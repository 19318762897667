export const locale = {
    lang: 'en',
    data: {
        HOME: {
            SHIPPING_FREE: 'Free shipping',
            ASSISTANCE24H: 'Assistance 24h',
            SHIPPING_FAST: 'Fast delivery',
            BEST_SELLERS: 'Best sellers',
            FIRST_ORDER_PRESENT: 'The gift on your first order',
            DISCOUNT_COUPON: '€25 DISCOUNT COUPON',
            HAVEN_T_YET_FOUND: 'Haven\'t found what you were looking for yet?',
            DESIGN_TOGETHER: 'Let\'s design together',
            SAY_ABOUT_US: 'That say about us',
        },
        NEWSLETTER: {
            PRESENT: 'Your welcome gift',
            DISCOUNT_COUPON: 'on your first order',
            REGISTER_AND_SUBSCRIBE: 'Register and sign up for the newsletter',
            START_NOW: 'Start now'
        },
        GENERAL: {
            OK: 'Ok',
            DELETE: 'Delete',
            NEXT: 'Next',
            CONFIRM_CANCEL: 'Confirm/Cancel',
            CONFIRM_NEW_FILE: 'Confirm/New file',
            CANCEL: 'Cancel',
            RETRY: 'Retry',
            CLICK_HERE: 'Click here',
            INSERT: 'Insert',
            ADD: 'Add',
            SAVE: 'Save',
            NO_PREDICTIONS: 'No predictions',
            NOT_FOUND: 'No results',
            EXIT: 'Exit',
            SUCCESS: 'Success',
            CONFIRM: 'Confirm',
            VERIFY: 'Verify',
            UNDO: 'Undo',
            BACK: 'Back',
            CONTINUE: 'Continue',
            SUBSCRIBE: 'Subscribe',
            SUBMIT_BUTTON: 'Submit',
            CALL: 'Call',
            DATA_ACCOUNT: 'Data account',
            ENTER_YOUR_EMAIL: 'Enter your email',
            ENTER_YOUR_NAME: 'Enter your name',
            ENTER_YOUR_SURNAME: 'Enter your last name',
            ENTER_YOUR_PHONE: 'Enter your phone',
            ENTER_YOUR_IDEA: 'Your idea',
            ENTER_TEXT: 'Enter text',
            CAPTCHA_ERROR: 'The text entered is incorrect',
            CAPTCHA_MANY_ERROR: 'You have exhausted your attempts, reload the page!',
            ENTER_YOUR_RECIPIENT: 'Enter the recipient',
            ENTER_YOUR_DESCRIPTION: 'Enter description',
            ENTER_YOUR_PROVINCE: 'Enter province',
            ENTER_ADDRESS: 'Enter address',
            ENTER_TAX_ID_CODE: 'Enter tax id code',
            ENTER_CAP: 'Enter cap',
            ENTER_YOUR_COUNTRY: 'Enter country',
            SELECT_LOCATION: 'Enter location',
            SELECT_SOCIETY: 'Select society type',
            SELECT_SECTOR: 'Select sector',
            GENERAL_SELECT: 'Select',
            ENTER_P_IVA: 'Enter VAT number',
            ENTER_BUSINESS_NAME: 'Enter business name',
            ENTER_PEC: 'Enter email PEC',
            ENTER_EMAIL: 'Enter email',
            SELECT_EVENT: 'Select event',
            SELECT_OPTION: 'Select option',
            ENTER_CODE: 'Enter unique code',
            ITALIAN: 'Italian',
            ENGLISH: 'English',
            ALMOST_THERE: 'We are almost there',
            COUPONS: 'Coupons',
            FIND_OUT_MORE: 'Find out more',
            NOTIFICATION_MANAGEMENT: 'Notification management',
            UPDATE_PASSWORD: 'Update password',
            COOKIE_POLICY: 'Cookie policy',
            PRIVACY_POLICY: 'Privacy policy',
            DETAIL: 'Detail',
            ACTIONS: 'Actions',
            DONT_SHOW: 'Do not show again',
            HOME: 'home',
            GO_HOME: 'Go to home',
            HELLO: 'Hello',
            INSTRUCTION: 'Instruction',
            ERRORS: {
                GENERIC_ERROR: 'Error, the operation could not be performed',
                ERROR_DOWNLOAD_DATA: 'Data downloaded incorrectly',
                FILE: 'File not loaded correctly',
                IMAGE: 'Image not uploaded correctly',
                PAGE_NOT_FOUND: 'Page not found',
                IMAGE_DIMENSION: 'File size is greater than 2 MB',
                IMAGE_DIMENSION_AZURE: 'File size is greater than 2 MB',
                RESOLUTION: 'The resolution of the inserted file is incorrect',
                IMAGE_FORMAT: 'File format is not supported',
                ERROR_REORDER_DATA: 'Data not reordered correctly',
                MAX_NUMBER_OF_FILE_REACHED: 'Limit of inserted files reached',
                NO_DATA_FOUND: 'No data found',
                MAX_SIZE_CONSOLE_ERROR: 'The file exceeds the maximum size of 1 GB.'
            },
            ERROR: 'Error',
            COMPLETED: 'Completed',
            CANCELLED: 'Cancelled',
            WATING: 'Waiting',
            IN_PROGRESS: 'In Progress',
            PAID: 'Paid',
            NOT_PAID: 'Not Paid',
            PRINT_FILES_LOADED: 'Print Files Loaded',
            PRINT_FILES__NOT_LOADED: 'Print Files Not Loaded',
            SHIPPED: 'Shipped',
            READY_FOR_DELIVERY: 'Ready For Delivery',
            PENDING_PAYMENT: 'Pending Payment',
            TO_BE_CONFERMED: 'To Be Confirmed',
            VERIFIED_FILE_PRINT_ORDER: 'Verified File - Print Order',
            PACKING: 'Packing',
            UNDER_CONSTRUCTION: 'Under Construction',
            FILE_WAITING: 'File Waiting',
            VERIFICATION_VAT_4: 'Verification VAT 4%',
            WAITING_FOR_NEW_FILE: 'Waiting For New File',
            PRINTING_TEST: 'Printing Test',
            IN_PRODUCTION: 'In Production',
            IN_PRESS: 'In Press',
            PAGE: 'Page',
            OF: 'of',
            DROP_IMAGE_VIEW: 'View',
            DROP_IMAGE_UPLOAD: 'Upload',
            DROP_IMAGE_ALREADY_VIEW: 'File already upload',
            DROP_IMAGE_DELETE: 'Delete',
            LABEL_REQUIRED: 'Fields required',
            VALID_FORMAT: 'Valid Formats: ',
            BUY: 'Buy'
        },
        UPLOADING_FILE: {
            DRAG: 'Trascina il file da caricare o clicca qui per selezionarlo dalla tua libreria',
            DRAG_1: 'Per lavorazioni con fronte e retro diversi puoi inserire un unico file PDF contenente entrambe le facciate su pagine diverse o due file distinti(PDF,TIFF,JPG) con le due facciate.',
            DRAG_2: 'Per lavorazioni di riviste o libri devi inviare due file distinti in PDF, uno per la copertina ed uno per le pagine interne.',
            DRAG_3: 'Per lavorazioni di riviste auto copertinate inviare un unico file PDF contenente tutte le pagine compresa la copertina.',
            DRAG_4: 'Non è possibile caricare cartelle compresse (ZIP, RAR, 7z, ecc.).'
        },
        NOTIFICATION_MANAGEMENT: {
            EVENT: 'Event',
            EMAIL: 'Email',
            NEW_NOTIFICATION: 'New notification'
        },
        ADDRESS: {
            DEFAULT: 'Indirizzo classico',
            BARTOLINI_ADDRESSES: 'Fermo deposito presso filiare Bartolini',
            NEW_SHIPPING_ADDRESS: 'New shipping address',
            MODIFY_SHIPPING_ADDRESS: 'Modify shipping address',
            NEW_BILLING_ADDRESS: 'New billing address',
            MODIFY_BILLING_ADDRESS: 'Modify billing address',
        },
        CASHBACK: {
            TITLE: 'Cashback program',
            SECTION_TITLE: 'The cash back program',
            HOW_IT_WORKS: 'How it works',
            HOW_IT_WORKS_DESCRIPTION: 'PressUP will issue a DISCOUNT COUPON during the month, calculated by multiplying ' +
                'the amount of spending made in the previous month on all PressUP products by the % indicated in the table. ' +
                'The amount of the monthly expense is calculated based on the value of the order indicated on the invoice, net of VAT.',
            BOX: {
                TITLE_1: 'For all customers',
                TITLE_2: 'Order and receive a discount',
                TITLE_3: 'Can be combined with other offers',
                TEXT_1: 'The Cashback program is reserved for all customers and applies to all orders placed online (www.pressup.it)',
                TEXT_2: 'The possibility of receiving a DISCOUNT COUPON proportional to the expenditure you have made in the previous month on all PressUP products',
                TEXT_3: 'It can be used on all products in the catalog and can be combined with other offers and promotions',
            },
            CARDS: {
                TITLE_1: '<span class="color-default">2,5%</span>',
                TITLE_2: '<span class="color-default">5%</span> discount coupon',
                TITLE_3: '<span class="color-default">7,5%</span> discount coupon',
                TITLE_4: '<span class="color-default">10%</span> discount coupon',
                TEXT_1: 'For a purchase between €1 and €499',
                TEXT_2: 'For a purchase between €500 and €1499',
                TEXT_3: 'For a purchase between €1500 and €4999 Per una spesa tra 1500€ e 4999€',
                TEXT_4: 'For a greater expense €5000'
            },
            FAQS: {
                TITLE_1: 'Cosa è il programma cashback?',
                TITLE_2: 'A chi è riservato?',
                TITLE_3: 'Quali sono gli ordini che vengono considerati per il calcolo del buono sconto?',
                TITLE_4: 'Dove posso vedere l\ammontare del mio buono spesa?',
                TITLE_5: 'Quali sono le caratteristiche del buono sconto?',
                TEXT_1: 'La possibilita\' di ricevere un BUONO SCONTO proporzionale alla spesa che hai effettuato nel mese precedente su tutti i prodotti di PressUP',
                TEXT_2: 'Il programma Cash Back è riservato a tutti i clienti e si applica a tutti gli ordini effettuati online (tramite la piattaforma pressup.it) nel mese precedente su tutti i prodotti di PressUP. Sono esclusi gli ordini inseriti offline (telefono, mail, messaggistica, etc.) dal servizio clienti.',
                TEXT_3: 'Ai fini del calcolo dell’ammontare della spesa mensile, saranno presi in considerazione gli ordini creati entro la fine del mese precendente e che soddisfano i seguenti requisiti:\n' +
                    '<ul><li>ordini in carta di credito o bonifico anticipato pagati entro la fine del mese precendente</li>' +
                    '<li>ordini in contrassegno spediti entro il mese precendente</li></ul>' +
                    'Sono considerati tutti gli ordini effettuati online (tramite la piattaforma pressup.it) nel mese precedente su tutti i prodotti di PressUP. Sono esclusi gli ordini inseriti offline (telefono, mail, messaggistica, etc.) dal servizio clienti.\n' +
                    'L\'ammontare della spesa risultante sara\' moltiplicato per la % percentuale indicata in tabella ottenendo cosi l\'importo del BUONO SCONTO.',
                TEXT_4: 'Nella tua area personale, nella sezione CashBack, potrai consultare l\'ammontare speso, il buono maturato del mese corrente e i buoni sconto maturati nei mesi precendenti.',
                TEXT_5: '<ul><li>È valido per 60 giorni a partire dalla data di emissione</li>' +
                    '<li>Puo’ essere utilizzato su tutti i prodotti del catalogo</li>' +
                    '<li>È cumulabile con offerte e promozioni</li>' +
                    '<li>Non È cumulabile con altri codici sconto</li>' +
                    '<li>Non è frazionabile</li></ul>'
            },
            HEADERS: {
                CODE: 'Code',
                DESCRIPTION: 'Description',
                VALUE: 'Value',
                RESIDUE: 'Residue',
                MINIMUM_SPENDING: 'Minimum spending',
                VALIDITY_START_DATE: 'Validity start date',
                EXPIRY_DATE: 'Expiry date'
            },
            NO_ELEMENTS_TO_SHOW: 'No coupons currently available'
        },
        ASSISTANCE: {
            TITLE: 'Assistance',
            NEW_REPORT: 'New report',
            HEADERS: {
                INSERTION_DATE: 'Insert date',
                PROCESSING_NUMBER: 'Proces. number',
                STATE: 'State',
                DESCRIPTION: 'Description'
            },
            STEPS: {
                FIND_PROCESSING: 'Find \n processing',
                FIND_PROCESSING_INLINE: 'Find processing',
                PROBLEM_DESCRIPTION: 'Problem \n description',
                PROBLEM_DESCRIPTION_INLINE: 'Problem description',
                SOLUTION: 'Solution',
            },
            DETAIL: {
                ORDER: 'Order',
                SUMMARY: 'Summary',
                N_COPIES: 'Number of copies of the alert',
                PRODUCT: 'Product',
                SOLUTION: 'Solution',
                PROPOSED_SOLUTION: 'Proposed solution',
                PROBLEMS_OF_SIGNALLING: 'Problems of the signalling',
                ADDITIONAL_NOTES: 'Additional notes',
                PHOTO_PROBLEMS: 'Photo of the problem',
                BACK: 'Back',
                NOTE: 'Nots'
            },
            STEP_1: {
                HEADER: 'Enter the machining number',
                N_WORKER: 'N° processing',
                INSERT_N_WORKER: 'Enter the processing number',
                NAME_WORKER: 'Name of the workmanship',
                INSERT_NAME_WORKER: 'Insert name of the workmanship',
                TOTAL_WORKER: 'The report covers the whole processing',
                N_COPIES_WORKER: 'The alert concerns the following number of copies',
                N_COPIES: 'Number of copies',
                INSERT_N_COPIES: 'Insert the number of copies',
                REPORT_ALREADY_MADE: 'Report already made. Contact support for more info.',
                ORDER_TO_DELIVERY: 'Your order is being delivered. Contact support for more info.'
            },
            STEP_2: {
                REASONS_FOR_REPORTING: 'Reasons for reporting',
                SELECT_REPORTING: 'Select one or more reporting issues',
                MY_FILE_NO_USED: 'My file was not used',
                WRONG_SIZE: 'Wrong size',
                SALTATETRANSPARENT: 'Saltate transparent',
                DOUBLE_SIDED: 'Double sided printed only on ovens',
                UNREGISTERED: 'Unregistered',
                WRONG_COLOR: 'Wrong color',
                DIFFERENT_MATERIAL: 'Different material',
                MISSING_COPIES: 'Missing copies',
                SPOTS_HALOS: 'Spots/halos',
                MISCUT: 'Miscut',
                MISSTRUCTURE: 'Misstructure',
                INCORRECT_MOUNTING: 'Incorrect mounting',
                MISSING_PIECES: 'Missing pieces',
                DAMAGED_GOODS: 'Damaged goods',
                DELAY: 'Delay',
                NON_MINE: 'Non-mine',
                OTHER_REASON: 'Other reason',
                NOTE: 'Note',
                DESCRIBE_NOTE: 'Describe reasons of report',
                DESCRIBE_SOLUTION: 'Describe the proposed solution',
                UPLOAD_PHOTO: 'Upload photos of the problem (max 4)',
                ADD_FILE: 'Add file',
                ADD_PDF: 'Add Pdf',
                PREVIEW: 'Preview',
                FILE_NAME: 'Filename',
                ACTION: 'Action',
                ERROR_STEP: 'To continue, please select at least one issue.',
                ERROR_STEP_ONE: 'To continue, please select a processing number and the processing id'
            },
            STEP_3: {
                PROPOSE_SOLUTION: 'Propose solution',
                REPINT: 'Reprint',
                OTHER_SOLUTION: 'Other solution',
                PROPOSE_A_SOLUTION: 'Propose a solution',
                WRITE_SOLUTION: 'Write us a solution...',
                ERROR_STEP: 'To continue, please select at least one solution.',
            },
            STEP_4: {
                REPORT_SUCCESS: 'Report sent successfully'
            }
        },
        ORDERS: {
            ORDERS: 'Orders',
            ORDER: 'Order',
            ORDERED_ON: 'Ordered on',
            TOTAL: 'Total',
            SEARCH_PRODUCT: 'Search product...',
            ORDER_DATA: 'Order data',
            NEW_ORDER: 'Order again',
            DOWNLOAD_INVOCE: 'Download invoice',
            NAME_PROCESSING: 'Name processing',
            SHIPPING: 'Shipping',
            SHIPPINGS: 'Shipping',
            ADDRESS_SHIPPING: 'Shipping address',
            VIEW_ALL: 'SEE MORE...',
            PAY_NOW: 'Pay now',
            UPLOAD_FILE: 'Upload file',
            SEARCH_PLACEHOLDER: 'Search order...',
            NO_ORDERS: 'No orders placed',
            CONFIRM_PDF_TITLE: 'Confirm PDF viewing',
            CONFIRM_PDF_DESCRIPTION: 'Confirm your order using the file we sent you to view or by uploading a new file',
            CONFIRM_PDF_BTN: 'Confirm PDF in View',
            UPLOAD_NEW_FILE: 'Upload new' +
                '\n' +
                'file',
            CONFIRM_CANCEL_ORDER: 'Confirm or cancel order',
            CONFIRM_CANCEL_SUB_TITLE: 'Confirm your order using the previously uploaded file or uploading a new file, otherwise cancel your order',
            CANCEL_ORDER: 'Cancel order',
            CONFIRM_FILE_OR_PRINT_TRIAL: 'Confirm file or print test',
            NO_ORDER_ADDED: 'No orders added',
            DISCOVER_CATALOGUE: 'Browse the catalog and add products to your cart',
            FILE_TO_UPLOAD: 'File to upload',
            FILE_UPLOADED: 'File uploaded',
        },
        ORDER_DETAIL: {
            GO_TO_ORDER: 'Go to order',
            ORDER_AGAIN: 'Order again',
            ORDER: 'Order',
            ORDER_ON: 'Order on',
            EXPECTED_DELIVERY: 'Expected delivery',
            NAME_PROCESSING: 'Name processing',
            NAME_PROC: 'Name process.',
            PROCESSING: 'Processing',
            SHIPPING: 'Shipping',
            PAYMENT_METHOD: 'Payment method',
            EMAIL: 'E-mail',
            PAYMENT_ON: 'Payed on',
            VIEW_INVOICE: 'View invoice',
            TOT_SHIPPING: 'Tot. shipping',
            TOT_PROCESSING: 'Tot. processing',
            TOT_VAT_EXCLUDED: 'Tot. VAT excluded',
            WITH_VAT: 'With VAT',
            BONUS_IMPORT: 'bonus import',
            TOTAL: 'Total',
            DISCOVER_MORE: 'Show more',
            DISCOVER_LESS: 'Show less',
            SHOWING: 'Orders shown',
            OF: 'of',
            RESULTS: 'results',
            ORDER_ITEM_DETAIL: 'Order details',
            EXTRA: 'Extra',
            DISCOUNT: 'Discount'
        },
        PROCESS_DETAIL: {
            ORDER_CHARACTERISTICS: 'Order characteristics (machining)',
            NUMBER_OF_COPIES: 'Number of copies',
            RETRO_COLOR: 'Retro color',
            EXTRA_NOTE: 'Extra note',
            PRINT_FORMAT: 'Print format',
            PAPER: 'Paper',
            BINDING: 'Binding',
            FOREHEAD_COLOR: 'Forehead color',
            GRAMMAGE: 'Grammage',
            FOLDING: 'Folding',
            INTERNAL_PLASTIFICATION: 'Internal plastification',
            ADMINISTRATIVE_INFORMATION: 'Administrative information',
            SHIPPING_PRICE: 'Shipping price',
            NET_PRICE: 'Net price',
            VAT: 'Vat',
            PAYMENT: 'Payement',
            AMOUNT_CARD: 'Amount to pay card',
            SHIPPING_INFORMATION: 'Shipping information',
            FORESEE_SHIPPING: 'Shipping foresee',
            SHIPPING: 'Shipping',
            ANONYMOUS_SHIPPING: 'Anonymous shipping',
            EXTRA: 'Extra'
        },
        ORDER_FILE: {
            PRINT_INFORMATION: 'Information for the press',
            UPLOADING_FILE: 'File upload',
            ASSOCIATE_WORK_FILE: 'Job file association:',
            REAL_FORMAT: 'Actual print size',
            REAL_FORMAT_ADVICE: '(if different from the one ordered e.g. ordered 21x29.7 but requested 20x28)',
            ABUNDANCE: 'Abundance',
            NUM_SUBJECT: 'Number of subjects',
            SAME_SUBJECT: 'Same subject',
            MULTI_SUBJECT: 'Multiple subjects',
            MULTI_SUBJECT_DISABLED: 'Multiple subject division disabled for this order',
            MULTI_SUBJECT_DISABLED_MAX_STACK: 'Multiple subject limit achieved for this process',
            NOTIFY: 'The selection requires an additional price',
            ADD_SUBJECT: 'Add',
            MULTI_SUBJECT_PARTITION: 'Multiple subject subdivision',
            REMAIN_COPIES: 'remaining copies',
            ERROR_OVERFLOW: 'The total number of copies must be:',
            INSERT_REMAIN_COPIES: 'Insert remaining copies',
        },
        CART: {
            TITLE: 'Cart',
            TITLE_SHIPPING: 'Manage shipping',
            EMPTY_CART: 'Your cart is currently empty.',
            TITLE_EMPTY_CART: 'Your Cart is Empty',
            SUBTITLE_EMPTY_CART: 'Add something to make me happy :)',
            HEADERS: {
                PHOTO: 'Photo',
                PROCESSING_NAME: 'Processing Name',
                QUANTITY: 'Quantity',
                DELIVERY: 'Delivery',
                GRAPHIC_SUPPORT: 'Graphic \n\n\n\n support',
                TOT: 'Total' +
                    '( VAT inlc. )',
                ACTION: 'Action'
            },
            SHIPPING_TYPE_HEADERS: {
                QUANTITY: 'Quantity',
                ADDRESS: 'Address',
                ANONYMOUS_SHIPPING: 'Anonymous shipping',
                SHIPPING_PRICE: 'Shipping price',
                DELIVERY_DATE: 'Expected delivery'
            },
            TOOLTIP_GRAPHIC_SUPPORT: 'By selecting "Graphics Support" the following will be performed:' +
                'the following adjustments:' +
                '\n' +
                '- Creating bleeds on print files' +
                '\n' +
                'High resolution file control' +
                '\n' +
                'In case of folders, positioning of the correct die on the submitted artwork.',
            ADD_NOTES: 'Add notes',
            MODAL_SUBTITLE: 'A tested stamp can be rich in the cost of the label at a cost of €30 + VAT.',
            UPDATE_SHIPPING: 'Update shipping',
            PRODUCTION_AND_DELIVERY: 'Production and delivery time',
            PRODUCTION_AND_DELIVERY_TEXT:  'The day of order entry is considered to count production times provided that the files suitable for printing and payment are received by 09:00.\n' +
                'The days from Monday to Friday are considered working days, excluding holidays.\n' +
                'Islands and more remote areas can be served by the courier in two or more working days.\n' +
                'For any doubts or clarifications, please contact us at 076-15.27.351.',
            ACCEPT_CONDITION: 'I accept the general conditions available on this',
            PAGE: 'page',
            SELECT_PAYMENT: 'Select your payment method',
            BANK_TRANSFER: 'Bonifico bancario',
            COUNTERSIGN: 'Countersign',
            COLLECT_ON_SITE: 'Collect on site',
            COLLECT_BARTOLINI_WAREHOUSE: 'Collection at the Bartolini warehouse',
            ADD_NEW_ADDRESS: 'Add new address',
            PROCESSING: 'Processing',
            EMPTY_CART_ACTION: 'Empty cart',
            APPLY_DISCOUNT: 'Apply discount code',
            CONTINUE_SHOPPING: 'Continue shopping',
            COMPLETE_ORDER: 'Complete order',
            DISCOUNT_COUPON: 'Discount coupon',
            APPLY: 'Apply',
            TAXABLE_AMOUNT_FOR_WORKS: 'Taxable amount for works:',
            VAT: 'VAT:',
            GRAPHIC_SUPPORT: 'Graphic support:',
            ADD_SHIPPING: 'Add shipping',
            STORAGE_STOP: 'Storage stop',
            SELECT_BRT: 'Select BRT branch',
            TOTAL_PRICE: 'Total price',
            WHICH_VAT: 'of which VAT',
            TOOLTIP_DELETING_ADDRESS: 'Deletion not possible: At least one address must be associated.',
            TECHNICAL_PRODUCT: 'Technical product',
            QUANTITY_PZ: 'pc.',
            OF_VAT: 'of which VAT',
            GO_CART: 'Go to cart',
            ALERT_BANK_TRANSFER: 'By selecting this payment method the processing will only begin when we receive the actual credit of the sum on our current account. Please therefore count at least 2 working days more than the expected delivery date.',
            NOTICE: 'Notice',
            PAY_AFTER: 'Pay after'
        },
        INVOICE: {
            ADDRESS_1: 'Via Cavour, 155b - 00045',
            ADDRESS_2: 'Roma (RM) - IT',
            ADDRESS_3: 'VAT number IT 10922761001',
            BILING_ADRESS: 'Billing adress',
            N_BILLING: 'N° billing',
            N_ORDER: 'N° order',
            AMOUNT_DUE: 'Amount due',
            DESCRIPTION: 'Description',
            COPIES: 'Copies',
            TOTAL: 'Total',
            PAYED_WITH: 'Payed with',
            TOT_NET: 'Tot. net',
            VAT: 'Vat',
            TOT_ORDER: 'Tot.order',
            DOWNLOAD_PDF: 'Download pdf',
            PRINT: 'Print',
            DATE_OF_ISSUE: 'Date of issue',
        },
        ERRORS_MAP: {
            REQUIRED: 'Is required',
            TOO_SHORT: 'Too short',
            INVALID: 'Is invalid',
            NOT_NUMBER: 'Not a number',
            WRONG_LENGTH: 'Wrong length',
            INVALID_TYPE: 'Invalid type',
            ALREADY_USED: 'Already used',
            VAT_NOT_VALID: 'Invalid VAT number',
            TAX_ID_NOT_VALID: 'Invalid tax code',
            BUSINESS_NAME_NOT_VALID: 'Invalid company name',
            PEC_NOT_VALID: 'Invalid certified email',
            UNIQUE_CODE_NOT_VALID: 'Invalid unique code',
        },
        HEADER: {
            PLACEHOLDER_SEARCH: 'What do you want to print?',
            NO_RESULT: 'No result',
            YOUR_BROWSER_NOT_SUPPORT_VOICE_RECOGNITION: 'Your browser not support voice recognition'
        },
        ACCOUNT_DATA: {
            CURRENT_PASSWORD: 'Current password',
            NEW_PASSWORD: 'New password',
            CONFIRM_PASSWORD: 'Confirm new password',
            ENTER_CURRENT_PASSWORD: 'Enter current password',
            ENTER_NEW_PASSWORD: 'Enter new password',
            ENTER_CONFIRM_PASSWORD: 'Enter confirm password'
        },
        AUTH: {
            ABOUT_US: {
                TITLE_SECTION_2: 'Your online digital and offset printing service',
                TEXT_1: 'Attiva sul mercato dal 2010, PressUP è una tech-company pura che offre l\'eccellenza nel campo della stampa online, combinando tecnologie digitali e offset.' +
                    'Un solution provider globale che fonda la propria crescita esponenziale sulla combinazione virtuosa di know-how tipografico, concept innovativo della vendita online di stampati e minuzioso controllo qualità lungo tutta la filiera.' +
                    'Tra gli asset aziendali, il customer care consulenziale, l’attenzione alla sostenibilità ambientale e i servizi a valore aggiunto come PressUP NOW che garantisce la consegna in giornata.',
                TEXT_2: 'Attiva sul mercato dal 2010, PressUP è una tech-company pura che offre l\'eccellenza nel campo della stampa online, combinando tecnologie digitali e offset.' +
                    'Un solution provider globale che fonda la propria crescita esponenziale sulla combinazione virtuosa di know-how tipografico, concept innovativo della vendita online di stampati e minuzioso controllo qualità lungo tutta la filiera.' +
                    'Tra gli asset aziendali, il customer care consulenziale, l’attenzione alla sostenibilità ambientale e i servizi a valore aggiunto come PressUP NOW che garantisce la consegna in giornata.'
            },
            SUSTAINABLE_PRINTING: {
                CERT_FSC: 'Certification FSC',
                CERT_PEFC: 'Certification PEFC',
                OF_COURSE_PAPER: 'Of course me ♡ the paper'
            },
            GENERAL: {
                LOGIN: 'Log in',
                WELCOME_TO_CASH_NOVA: 'Welcome to CASH NOVA',
                OR: 'Or',
                SUBMIT_BUTTON: 'Submit',
                NO_ACCOUNT: 'Don\'t have an account?',
                SIGNUP_BUTTON: 'Sign Up',
                FORGOT_BUTTON: 'Forgot Password',
                BACK_BUTTON: 'Back',
                PRIVACY: 'Privacy',
                LEGAL: 'Legal',
                CONTACT: 'Contact',
                LOADING_CONTENT: 'We are recovering your data...',
                LOGOUT: 'Are you sure you want to log out?'
            },
            LOGIN: {
                LOGIN: 'Login',
                EMAIL: 'Email',
                PASSWORD: 'Password',
                INSERT_PASSWORD: 'Insert password',
                STAY_CONNECTED: 'Stay connected',
                FORGOT_PASSWORD: 'Forgot password',
                OR_SIGN_IN: 'or sign in with',
                NEW_USER: 'New user',
                CREATE_ACCOUNT: 'Create your account',
                REGISTER_TEXT_1: 'Click "Register", enter the required data and you can start shopping.',
                REGISTER_TEXT_2: 'Subscribe to the newsletter to receive a €25 discount on your first order.',
                REGISTER: 'Register',
                PASSWORD_RECOVERY: 'Password recovery',
                REGISTRATION: 'Registration'
            },
            FORGOT: {
                TITLE: 'Forgotten Password?',
                DESC: 'Enter your email to reset your password',
                SUCCESS: 'Your account has been successfully reset.'
            },
            RECOVERY_PASSWORD: {
                CREATE_PASSWORD: 'Create new password',
                CONSTRAINTS: 'The password must contain at least: An uppercase character, a lowercase character, a number, a special character, must be at least 8 characters.',
                SAVE: 'Save'
            },
            REGISTER: {
                EMAIL: 'Email',
                PASSWORD: 'Password',
                INSERT_PASSWORD: 'Insert password',
                CONFIRM_PASSWORD: 'Confirm Password',
                NEWS_LETTER_SUBSCRIBE: 'I ask to subscribe to the newsletter to receive the <b>&nbsp;  discount of € 25</b>',
                ACCEPT_CONDITION: 'I accept the conditions on the processing of personal data',
                CREATE_ACCOUNT: 'Create your account',
                REGISTER: 'Register',
            },
            FOOTER: {
                SUBSCRIBE_THE_NEWSLETTER: 'subscribe to the newsletter',
                DATA_NOT_SEND: 'Your data will not be disclosed to third parties',
                ENTER_YOUR_EMAIL: 'Enter your e-mail',
                ACCEPT_PRIVACY: 'I accept the privacy policy',
                VAT_NUMBER_FISCAL_CODE: 'Vat number / Fiscal code',
                SHIPMENTS: 'Shipments',
                GENERAL_CONDITIONS_OF_SALE: 'General conditions of Sale',
                ABOUT_US: 'About us',
                FILE_GUIDELINES: 'File guidelines',
                CONTACTS: 'Contacts',
                SUSTAINABILITY: 'Sustainability',
                PRINT: 'Print',
                PRINTING_TECHNIQUES: 'Printing techniques',
                SUSTAINABLE_PRINTING: 'Sustainable Printing'
            },
            FIRST_ACCESS: {
                SUBTITLE: 'Enter the VAT number and telephone number\nof the legal representative to continue.',
                P_IVA: 'VAT',
                P_IVA_PLACEHOLDER: 'Enter your VAT number',
                LEGAL_NUMBER: 'Legal representative telephone number',
                LEGAL_NUMBER_PLACEHOLDER: 'Enter your phone number'
            },
            OTP_CODE: {
                INSERT_CODE: 'Insert code',
                INSERT_CODE_GENERATED: 'Insert the OTP code generated by the authenticator app',
                CODE: 'Code',
                WRONG_CODE: 'Wrong code. Enter a new code',
            },
            INPUT: {
                EMAIL: 'Email',
                FULLNAME: 'Fullname',
                PASSWORD: 'Password',
                USERNAME: 'Username'
            },
            FORGOT_PASSWORD: {
                FORGOT: 'Forgot password?',
                ENTER_EMAIL_FOR_RECOVERY_PASSWORD: 'Enter your email to recover your password'
            },
            VALIDATION: {
                PASSWORD_NOT_EQUALS: 'Password and confirm password are not equals',
                INVALID: '{{name}} is not valid',
                INVALID_FEMALE: '{{name}} is not valid',
                REQUIRED: '{{name}} is required',
                MIN_LENGTH: '{{name}} minimum length is {{min}}',
                AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
                NOT_FOUND: 'The requested {{name}} is not found',
                INVALID_LOGIN: 'The login detail is incorrect',
                REQUIRED_FIELD: 'Required field',
                MIN_LENGTH_FIELD: 'Minimum field length:',
                MAX_LENGTH_FIELD: 'Maximum field length:',
                INVALID_FIELD: 'Field is not valid',
                BIRTH_DAY_DIVERGENT_FROM_CFIS: 'Birthday divergent from that of the fiscal code',
                INVALID_COUNTRY: 'The selected country is invalid',
                INVALID_DATE: 'Date is invalid'
            },
            ERRORS: {
                CAPTCHA_ERR: 'Error in recaptcha, please try again in a few moments.',
                LOGIN_ERROR: 'Errore durante il login',
                FIRST_ACCESS_ERROR: 'Errore primo accesso',
                WALLET_ONBOARDING_NOT_PRESENT: 'Wallet onboading non presente',
                NO_ACCOUNT_DETAILS: 'Unable to retrieve user details'
            }
        },
        ECOMMERCE: {
            COMMON: {
                SELECTED_RECORDS_COUNT: 'Selected records count: ',
                ALL: 'All',
                SUSPENDED: 'Suspended',
                ACTIVE: 'Active',
                FILTER: 'Filter',
                BY_STATUS: 'by Status',
                BY_TYPE: 'by Type',
                BUSINESS: 'Business',
                INDIVIDUAL: 'Individual',
                SEARCH: 'Search',
                IN_ALL_FIELDS: 'in all fields'
            },
            ECOMMERCE: 'eCommerce',
            CUSTOMERS: {
                CUSTOMERS: 'Customers',
                CUSTOMERS_LIST: 'Customers list',
                NEW_CUSTOMER: 'New Customer',
                DELETE_CUSTOMER_SIMPLE: {
                    TITLE: 'Customer Delete',
                    DESCRIPTION: 'Are you sure to permanently delete this customer?',
                    WAIT_DESCRIPTION: 'Customer is deleting...',
                    MESSAGE: 'Customer has been deleted'
                },
                DELETE_CUSTOMER_MULTY: {
                    TITLE: 'Customers Delete',
                    DESCRIPTION: 'Are you sure to permanently delete selected customers?',
                    WAIT_DESCRIPTION: 'Customers are deleting...',
                    MESSAGE: 'Selected customers have been deleted'
                },
                UPDATE_STATUS: {
                    TITLE: 'Status has been updated for selected customers',
                    MESSAGE: 'Selected customers status have successfully been updated'
                },
                EDIT: {
                    UPDATE_MESSAGE: 'Customer has been updated',
                    ADD_MESSAGE: 'Customer has been created'
                }
            }
        },
        PAGES: {
            GENERIC_CATEGORY: {
                BANNER_TITLE: 'Haven\'t found what you\'re looking for?',
                BANNER_SUBTITLE: 'Fill out the form, add the features you prefer and request a personalized quote. You will receive a response as soon as possible.',
                BANNER_BTN_TITLE: 'Request a personalized quote',
                RELATED_PRODUCTS: 'Related products',
                LEARN_MORE: 'Learn more'
            },
            PROFILE: {
                TITLE: 'Account',
                ACCOUNT_DATA: 'Account data',
                HOW_KNOW_US: 'How did you know us?',
                SHIPPING_ADDRESSES: 'Shipping addresses',
                SHIPPING_ADDRESS: 'Shipping address',
                BILLING_ADDRESSES: 'Billing data',
                BILLING_ADDRESS: 'Billing address',
                BILLING_ADDRESSES_SUB_TITLE: 'Once the billing data has been saved, it cannot be changed',
                ORDERS: 'Orders',
                ARCHIVED_ORDERS: 'Archived orders',
                CASHBACK: 'Cashback',
                NOTIFICATION_MANAGEMENT: 'Notification management',
                ASSISTANCE: 'Assistance',
                UPDATE_PASSWORD: 'Update password',
                LOG_OUT: 'Log-out',
                ADD_ADDRESS: 'Add address',
                UPDATE_ADDRESS: 'Update address',
                ADD_NOTIFICATION: 'Add notification',
                NO_NOTIFICATION: 'No notifications added at the moment',
                NOTIFICATION_PAGINATION: 'Showing {first} to {last} of {totalRecords} entries',
                SHIPPING_ADDRESSES_DATA: {
                    DEFAULT_ADDRESS: 'Default address',
                    SET_DEFAULT_ADDRESS: 'Set as default',
                    MOBILE: 'Mobile'
                }
            },
            SOCIETY: {
                NAME: 'Society',
                SECTOR: 'Sector',
                P_IVA: 'P.IVA',
                PHYSICAL_PERSSON: 'Physical person',
                ASSOCIATION: 'Association',
                INDIVIDUAL_COMPANY: 'Individual company',
                SIMPLE_COMPANY: 'Simple company',
                BUSINESS_NAME: 'Business name',
                UNIQUE_CODE: 'Unique code'
            },
            STATIC_PAGES: {
                GENERAL_CONDITIONS_SALE: {
                    TITLE: 'Condizioni generali di vendita'
                },
                COOKIE_POLICY: {
                    TITLE: 'Cookies Policy di PressUp',
                    CONTENT: '<b>PressUp</b> utilizza cookie per fornire servizi che migliorino l’esperienza di navigazione\n' +
                        'degli utenti.<br><br>' +
                        'Come utilizziamo i cookie:<br>' +
                        '<ul><li>Utilizziamo cookie di navigazione anonimi, necessari per consentire agli utenti di\n' +
                        'navigare correttamente su <b>PressUp</b>, utilizzare tutte le funzionalità ed accedere ad aree\n' +
                        'riservate.</li>' +
                        '<li> Utilizziamo cookie analitici per capire meglio come i nostri utenti utilizzano il sito web,\n' +
                        'per ottimizzare e migliorare il sito, rendendolo sempre interessante e rilevante per gli\n' +
                        'utenti.</li></ul>' +
                        'Queste informazioni sono anonime, e utilizzate solo a fini statistici.\n' +
                        'Utilizziamo i cookie commerciali anonimi per mostrarti le nostre pubblicità su altri siti\n' +
                        'web, in base ai prodotti che hai visualizzato nelle navigazioni precedenti. Questi cookie\n' +
                        'vengono solitamente inseriti da parti terze di fiducia (come Google attraverso il\n' +
                        'remarketing per la pubblicità display), tuttavia, PressUp non consente a tali società di\n' +
                        'raccogliere informazioni personali relative agli utenti. Ciò significa che sarà garantito\n' +
                        'l\'anonimato e i dati personali non verranno usati da altri siti web o database di marketing.\n' +
                        'Come modificare o disattivare le preferenze dei cookie<br>' +
                        '<ul><li>Dalle impostazioni del tuo browser (Internet Explorer, Safari, Firefox, Chrome, ecc.)' +
                        'potrai stabilire quali sono i cookie che desideri ricevere/mantenere e quali no. Per' +
                        'sapere dove puoi trovare suddette impostazioni, utilizza la guida del tuo browser.</li>' +
                        '<li>I visitatori possono disattivare Google Analytics per la pubblicità display e personalizzare gli annunci della Rete Display di Google utilizzando la Gestione' +
                        'preferenze annunci.</li> '
                },
                CONTACT: {
                    VIDEO_CALL: 'Fissa una video call',
                    SUBTITLE_VIDEO_CALL: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
                    SEND: 'Invia',
                    FORM: {
                        NAME: 'Nome',
                        ENTER_NAME: 'Inserisci il nome',
                        SURNAME: 'Cognome',
                        ENTER_SURNAME: 'Inserisci il cognome',
                        PHONE_NUMBER: 'Numero di telefono',
                        ENTER_PHONE_NUMBER: 'Inserisci il numero di telefono',
                        EMAIL: 'E-mail',
                        ENTER_EMAIL: 'Inserisci l\'e-mail',
                        MESSAGE: 'Messaggio',
                        ENTER_MESSAGE: 'Inserisci il tuo messaggio'
                    },
                    CHAT: 'Chat',
                    SOCIAL: 'Social'
                },
                ESTIMATE: {
                    TITLE: 'Preventivo personalizzato',
                    SUB_TITLE: 'Compila il form con le caratteristiche del lavoro desiderato, riceverai una risposta nel più breve tempo possibile',
                    FORM_ESTIMATE_REQUEST: {
                        TITLE: 'Richiedi un preventivo',
                        JOB_NAME: 'Job name',
                        NUMBER_OF_COPIES: 'Number of copies',
                        JOB_TYPOLOGY: 'Job typology',
                        PRODUCT: 'Product',
                        CLOSED_FORMAT: 'Closed format',
                        OPENED_FORMAT: 'Open format',
                        MEASURE_STRAPS: 'Measure straps',
                        NUMBER_OF_PAGES_WITH_COVER: 'Number of page with cover',
                        PRINT_COLORS_OR_B_N: 'Print (colors or B/W)',
                        COVER: 'Cover',
                        INTERNAL_PAPER_TYPE: 'Internal paper type',
                        COVER_PAPER_TYPE: 'Cover paper type',
                        BINDING: 'Binding',
                        LAMINATION: 'Lamination',
                        DESCRIPTION: 'Description',
                        PLACEHOLDER_JOB_NAME: 'Insert job name',
                        PLACEHOLDER_NUMBER_OF_COPIES: 'Insert copies number',
                        PLACEHOLDER_JOB_TYPOLOGY: 'Select job typology',
                        PLACEHOLDER_PRODUCT: 'Select product',
                        PLACEHOLDER_CLOSED_FORMAT: 'Insert closed format',
                        PLACEHOLDER_OPENED_FORMAT: 'Insert open format',
                        PLACEHOLDER_MEASURE_STRAPS: 'Insert measure straps',
                        PLACEHOLDER_NUMBER_OF_PAGES_WITH_COVER: 'Insert number od pages with cover',
                        PLACEHOLDER_PRINT_COLORS_OR_B_N: 'Select color',
                        PLACEHOLDER_COVER: 'Seleziona color',
                        PLACEHOLDER_INTERNAL_PAPER_TYPE: 'Insert internal paper type',
                        PLACEHOLDER_COVER_PAPER_TYPE: 'Insert cover paper type',
                        PLACEHOLDER_BINDING: 'Insert binding',
                        PLACEHOLDER_LAMINATION: 'Insert lamination',
                        PLACEHOLDER_DESCRIPTION: 'Insert description',
                    },
                    FORM_CONTACT_REQUEST: {
                        TITLE: 'Contatto',
                        NOMINATIVE: 'Nominative',
                        PLACEHOLDER_NOMINATIVE: 'Insert nominative',
                        PHONE: 'Telefono',
                        PLACEHOLDER_PHONE: 'Insert phone',
                        EMAIL: 'E-mail',
                        SEND_REQUEST: 'Invia richiesta'
                    }
                },
                PRIVACY: {
                    TITLE: 'Allegato alle condizioni<br> generali di contratto',
                    SUBTITLE: 'INFORMATIVA SUL TRATTAMENTO DATI PERSONALI',
                    CONTENT: 'Ai sensi dell’art. 13 del decreto legislativo 196 del 30 giugno 2003 “Codice in materia di protezione dei dati personali”, il Cliente come designato nelle Condizioni Generali di' +
                        'contratto è informato e, per quanto di ragione, espressamente consente:\n' +
                        'a) che i dati forniti per lo svolgimento della istruttoria preliminare e i dati relativi allo svolgimento del rapporto contrattuale vengano trattati dalla Press Up s.r.l. con sede in' +
                        'Via E.Q. Visconti n. 90 - 00193 Roma iscritta presso la Camera di Commercio di Roma al n.1265559 del Registro delle imprese, CF n.10922761001 partita IVA n.10922761001\n' +
                        'di seguito indicata come “Press Up S.r.l.”, indirizzo di posta elettronica certificata\n' +
                        'pressup@legalmail.it per finalità di:\n' +
                        '- adempimento di obblighi legali, ivi inclusi gli obblighi di identificazione, conservazione\n' +
                        'e segnalazione previsti dalle normative antiriciclaggio (D.lgs. 56/2004);\n' +
                        '- gestione dei rapporti contrattuali ed in particolare delle obbligazioni di cui ai contratti\n' +
                        'di vendita, quali ad esempio la spedizione dei plichi all’indirizzo di residenza, la\n' +
                        'fatturazione, la stampa dei materiali consegnati direttamente dal Cliente;\n' +
                        '- elaborazione statistica;\n' +
                        '- tutela e recupero dei crediti;\n' +
                        '- marketing, informazione commerciale, pubblicità, manifestazioni a premi, rilevazione\n' +
                        'del grado di soddisfazione della clientela (il conferimento dei dati per tale ultima finalità\n' +
                        'è facoltativo e il loro utilizzo è subordinato al consenso dell’interessato) mediante\n' +
                        'i) elaborazione elettronica;\n' +
                        'ii) consultazione;\n' +
                        'iii) l’utilizzo di sistemi automatizzati di chiamata, senza l’intervento di un operatore,\n' +
                        'posta anche elettronica, telefax e messaggi di tipo MMS (Multimedia Message Service)\n' +
                        'e SMS (Short Message Service);\n' +
                        '- ogni altra opportuna operazione relativa al conseguimento delle predette finalità, anche\n' +
                        'per mezzo dei seguenti soggetti ai quali i dati possono essere comunicati (anche all\'estero\n' +
                        'in Paesi appartenenti e non appartenenti all\'Unione Europea) ed in particolare: 1) di\n' +
                        'soggetti specificamente incaricati quali consulenti, dipendenti e altri collaboratori a ciò\n' +
                        'abilitati per i trattamenti necessari o connessi allo svolgimento delle attività precontrattuali (istruttoria, valutazione dell’affidabilità finanziaria, ecc.) ed all’esecuzione\n' +
                        'del contratto; \n' +
                        '2) di terzi che svolgono o forniscono specifici servizi strettamente funzionali\n' +
                        'all’esecuzione del rapporto contrattuale (anche mediante trattamenti continuativi), quali:\n' +
                        '- società di servizi informatici;\n' +
                        '- banche e società che svolgono servizi di pagamento;\n' +
                        '- assicurazioni;\n' +
                        '- organismi associativi propri del settore della tipografia e stampa nonché a tutti gli altri\n' +
                        'soggetti ai quali la comunicazione sia dovuta per il raggiungimento di finalità ed obblighi\n' +
                        'di legge;\n' +
                        '- prestatori di assistenza, società controllate o collegate a Press Up s.r.l. o dalla stessa\n' +
                        'incaricate, in Italia o all’estero per il raggiungimento delle finalità o altre compagnie di\n' +
                        'assicurazione e/o società di servizi per la distribuzione del rischio;\n' +
                        '- subappaltatori incaricati;\n' +
                        '- agenti;\n' +
                        '- società di factoring;\n' +
                        '- società di recupero crediti;\n' +
                        '- soggetti che forniscono informazioni commerciali;\n' +
                        '- avvocati o commercialisti;\n' +
                        'b) che i predetti dati possono essere raccolti sia presso l’interessato sia presso terzi;\n' +
                        'c) che il conferimento dei dati richiesti, siano essi acquisiti in base ad un obbligo di legge\n' +
                        'ovvero in quanto strettamente funzionali all’esecuzione del rapporto contrattuale, è\n' +
                        'obbligatorio e che un eventuale rifiuto di fornirli comporta l’impossibilità di svolgere le\n' +
                        'attività richieste per la conclusione e per l’esecuzione del contratto e segnatamente con\n' +
                        'riferimento alla conclusione ed esecuzione del contratto on line.\n' +
                        'In relazione al trattamento dei predetti dati il Cliente, in base all’art. 7 del citato d.lgs.\n' +
                        '196/2003, ha il diritto di ottenere dalla Press Up S.r.l.: '
                },
                FAQS: {
                    TABS: {
                        GENERAL_INFO: 'Info Generali',
                        ACCOUNT_MANAGEMENT: 'Gestione account',
                        ORDER_CREATION: 'Creazione ordine',
                        ORDER_MANAGEMENT: 'Gestione ordine',
                        SHIPMENTS: 'Spedizioni',
                        ADMINISTRATION: 'Administration',
                        CASHBACK_PROGRAM: 'Programma cashback'
                    },
                    GENERAL_INFO: {
                        TITLE_1: 'Qual è la differenza tra offerta è promozione?',
                        TITLE_2: 'POSSO UTILIZZARE L\'OFFERTA "PROVACI A 1€"?',
                        TITLE_3: 'AVETE UN CAMPIONARIO DELLE VOSTRE CARTE?',
                        TITLE_4: 'COME POSSO OTTENERE UN PREVENTIVO?',
                        TITLE_5: 'HO BISOGNO DI INDICAZIONI PER LA COSTRUZIONE DEI FILE DA CARICARE PER LA STAMPA: DOVE LE TROVO?',
                        TITLE_6: 'POSSO RICHIEDERE UN SUPPORTO PER L\'ELABORAZIONE GRAFICA?',
                        TITLE_7: 'POSSO PUBBLICARE IL MARCHIO FSC® SUL MIO STAMPATO?',
                        FAQ_1: 'L\'offerta è il miglior prezzo disponibile sul sito per alcune combinazioni prodotto/caratteristiche, ottenuto grazie all\'ottimizzazione dei processi sulla relativa lavorazione. Le promozioni sono invece ribassi di prezzo che vengono applicati su uno o più prodotti per un periodo limitato di tempo.',
                        FAQ_2: 'Puoi approfittare dell\'offerta solo se hai partita IVA e non hai mai effettuato ordini su pressup.it. Accedi alla sezione specifica e segui le indicazioni riportate sul sito.',
                        FAQ_3: 'Sì, puoi richiedere il nostro campionario carte, al costo di 10€ + IVA, inviando una e-mail ad assistenza@pressup.it.',
                        FAQ_4: 'Sul sito, accedendo alla pagina del prodotto che ti interessa, puoi visualizzare in tempo reale e senza alcun impegno il prezzo (per singolo soggetto di stampa) in base alle caratteristiche impostate e al tempo di consegna selezionato. Ti consigliamo di effettuare l\'accesso per visualizzare il prezzo corretto.\n' +
                            'Se navigando sul sito non hai trovato quello che cerchi, puoi richiedere un preventivo personalizzato tramite il link in basso nella pagina o inviando una e-mail all\'indirizzo preventivi@pressup.it',
                        FAQ_5: 'Sul sito, nella sezione Guida per la costruzione del file, puoi trovare le principali linee guida per la costruzione dei file. Inoltre, accedendo alla pagina del prodotto che ti interessa, hai la possibilità di scaricare il template per la costruzione del file per la stampa dell\'ordine. Per ulteriori dubbi ti invitiamo a contattare il nostro servizio clienti.',
                        FAQ_6: 'Su specifica richiesta, possiamo occuparci dell\'elaborazione grafica per il tuo ordine:\n' +
                            '<ul><li>Creazione abbondanze sul file di stampa</li>' +
                            '<li>Controllo alta risoluzione del file</li>' +
                            '<li>Per il piccolo formato, in caso di ricezione di loghi e testi, creazione del file di stampa</li>' +
                            '<li>Per le cartelline, posizionamento della corretta fustella sulla grafica inviata</li></ul>' +
                            'Terminata l\'elaborazione ti invieremo via e-mail il pdf in visione da approvare per procedere con la stampa.\n' +
                            'Il servizio di elaborazione grafica prevede un costo aggiuntivo a partire da 10€+IVA. Ti suggeriamo di inviarci una e-mail all\'indirizzo ordini@pressup.it prima di effettuare l\'ordine così ti forniremo tutte le indicazioni necessarie.',
                        FAQ_7: 'La pubblicazione del marchio FSC® deve essere autorizzata dall\'Ente Certificatore. Per essere approvato, il logo deve avere le seguenti misure:' +
                            '<ul><li>Logo “Standard” orizzontale: altezza 12 mm</li>' +
                            '<li>Logo “Minilab” orizzontale: altezza 8 mm.</li></ul>' +
                            'Assicurati quindi che il logo soddisfi questi requisiti e invia una e-mail ad ordini@pressup.it, allegando il file .pdf della pagina in cui deve essere posizionato. Il nostro servizio clienti inoltrerà la richiesta all\'Ente e te ne comunicherà l\'esito.'
                    },
                    ACCOUNT_MANAGEMENT: {
                        TITLE_1: 'POSSO MODIFICARE L\'INDIRIZZO E-MAIL DI REGISTRAZIONE AL SITO?',
                        TITLE_2: 'POSSO MODIFICARE I DATI DEL MIO ACCOUNT?',
                        TEXT_1: 'Per modificare l\'indirizzo di registrazione, invia una e-mail ad assistenza@pressup.it, indicando la nuova e-mail da sostituire. Nel caso in cui il nuovo indirizzo sia già presente sui nostri sistemi sarai contattato dal nostro servizio clienti.',
                        TEXT_2: 'Per modificare i dati del tuo account, invia una e-mail ad assistenza@pressup.it.\n' +
                            'Ti ricordiamo che non puoi modificare partita IVA e codice fiscale: in tal caso è necessario registrare un nuovo account.'
                    },
                    ORDER_CREATION: {
                        TITLE_1: 'DEVO REGISTRARMI AL SITO PER FARE UN ORDINE?',
                        TITLE_2: 'COME POSSO EFFETTUARE UN ORDINE?',
                        TITLE_3: 'QUALI SONO LE MODALITA\' DI PAGAMENTO DELL\'ORDINE?',
                        TITLE_4: 'E\' POSSIBILE AVERE DELLE MODALITA\' DI PAGAMENTO DIVERSE RISPETTO A QUELLE PREVISTE SUL SITO?',
                        TITLE_5: 'DOVE TROVO LE INFORMAZIONI PER EFFETTUARE IL PAGAMENTO TRAMITE BONIFICO?',
                        TITLE_6: 'STO FACENDO UN ORDINE: PERCHE\' IL PAGAMENTO IN CONTRASSEGNO E\' DISABILITATO?',
                        TITLE_7: 'POSSO FARE UN UNICO ORDINE PER PIU\' PRODOTTI?',
                        TITLE_8: 'POSSO FARE UN UNICO ORDINE PER UNO STESSO PRODOTTO CON PIU\' SOGGETTI?',
                        TITLE_9: 'POSSO RICHIEDERE UNA PROVA DI STAMPA?',
                        TITLE_10: 'QUANDO E COME POSSO CARICARE I FILE PER LA STAMPA?',
                        TITLE_11: 'POSSO DUPLICARE UN ORDINE?',
                        TEXT_1: 'Sì, è necessario registrare un account per poter effettuare un ordine ed accedere ai servizi offerti. Clicca su Registrati in alto a destra ed inserisci le informazioni richieste.',
                        TEXT_2: 'Sul sito, accedendo con il tuo accounti alla pagina del prodotto che ti interessa, puoi visualizzare in tempo reale e senza alcun impegno il prezzo (per singolo soggetto di stampa) in base alle caratteristiche impostate e al tempo di consegna selezionato. Ti consigliamo di effettuare l\'accesso per visualizzare il prezzo corretto. Se navigando sul sito non hai trovato quello che cerchi, puoi richiedere un preventivo personalizzato tramite il link in basso nella pagina o inviando una e-mail all\'indirizzo preventivi@pressup.it',
                        TEXT_3: 'Puoi scegliere tra diversi metodi di pagamento:' +
                            '<ul><li>Carta di credito: Visa, Mastercard, Paypal e American Express</li>' +
                            '<li>Contrassegno:pagamento in contanti o con assegno bancario direttamente al corriere al momento della consegna</li>' +
                            '<li>Bonifico bancario anticipato</li></ul>' +
                            'Tutti i metodi di pagamento sono privi di commissioni.',
                        TEXT_4: 'Le modalità di pagamento sono solo quelle riportate sul sito.',
                        TEXT_5: 'Le informazioni sono riportate in basso nella mail di riepilogo che ricevi a conferma dell\'ordine effettuato.\n' +
                            'Per comodità le riportiamo di seguito:' +
                            'Press Up s.r.l.' +
                            'Banca Unicredit' +
                            'Bic: UNCRITM1D80' +
                            'IBAN: IT78C0200873190000401451375',
                        TEXT_6: 'Il pagamento in contrassegno non è disponibile per i prodotti in offerta.',
                        TEXT_7: 'Non gestiamo il carrello, pertanto è necessario effettuare un ordine per ciascun prodotto desideri acquistare. In caso di pagamento tramite bonifico bancario, puoi effettuare un pagamento unico per tutti gli ordini registrati, indicando nella causale il relativo numero di riferimento.',
                        TEXT_8: 'Il multisoggetto è previsto solo su alcuni prodotti con un costo aggiuntivo di 5€ + IVA a soggetto. Ti suggeriamo di contattare il nostro servizio clienti prima di effettuare l\'ordine così verificheremo la fattibilità della richiesta e, in caso positivo, ti forniremo tutte le indicazioni necessarie.',
                        TEXT_9: 'Puoi richedere la prova di stampa su Libri e Cataloghi a partire da 30€ + IVA: puoi effettuare la richiesta direttamente da sito in fase di ordine nella sezione di caricamento file. Ti ricordiamo che la prova di stampa serve a verificare la correttezza dell\'impaginazione, della sequenza delle pagine e la tipologia di carta, mentre non deve essere utilizzata come campione colore. Per tutti gli altri prodotti la prova di stampa non è disponbile, ma puoi selezionare una quantità minima e quindi richiedere un numero ridotto di copie. Per maggiori informazioni ti invitiamo a contattare il nostro servizio clienti.',
                        TEXT_10: 'Dopo aver inserito le caratteristiche del prodotto scelto, una volta confermato l\'ordine, ti viene richiesto di caricare i file relativi alla lavorazione scelta. È possibile caricare i file anche in un secondo momento accedendo alla sezione \'I miei ordini\'.',
                        TEXT_11: 'Se vuoi duplicare un ordine, devi necessariamente effetuarne uno nuovo dal sito, selezionando le stesse caratteristiche del precedente. Se l\'ordine da duplicare è relativo all\'anno in corso o al precedente, in fase di creazione del nuovo ordine, puoi richiedere nel campo Note di utilizzare gli stessi file di stampa dell\'ordine precedente, indicandone il numero, e di inviarti un pdf in visione prima di procedere con la stampa. Per qualsiasi dubbio ti invitiamo a contattare il nostro servizio clienti.'
                    },
                    ORDER_MANAGEMENT: {
                        TITLE_1: 'DA QUANDO PARTE LA LAVORAZIONE DEL MIO ORDINE?',
                        TITLE_2: 'POSSO ANTICIPARE O POSTICIPARE UN ORDINE?',
                        TITLE_3: 'POSSO ANNULLARE UN ORDINE?',
                        TITLE_4: 'POSSO MODIFICARE UN ORDINE?',
                        TITLE_5: 'A CHE PUNTO E\' IL MIO ORDINE?',
                        TEXT_1: 'La lavorazione dell\'ordine viene avviata dal momento in cui riceviamo i file ideonei alla stampa e, in caso di pagamento con carta di credito o bonifico, l\'accredito effettivo dell\'importo dell\'ordine sul nostro conto corrente: in caso di pagamento con bonifico anticipato, è necessario pertanto considerare almeno 2 giorni lavorativi in più rispetto alla data di consegna indicata.',
                        TEXT_2: 'Dato l\'elevato livello di automazione del nostro sistema, non è possibile intervenire per modificare le tempistiche di un ordine già entrato nel flusso delle lavorazioni. Ti suggeriamo di contattare il nostro servizio clienti per verificare l\'effettivo stato dell\'ordine e valutare la fattibilità della richiesta.',
                        TEXT_3: 'Puoi annullare il tuo ordine solo se la lavorazione non è stata avviata. Ti suggeriamo di contattare il nostro servizio clienti per verificare l\'effettivo stato dell\'ordine e valutare la fattibilità della richiesta. Naturalmente se hai scelto una forma di pagamento anticipato (carta di credito o bonifico), ti rimborseremo l\'importo versato: il rimborso sarà effettuato tramite bonifico bancario sull\'IBAN che ti sarà richiesto al momento dell\'annullamento dell\'ordine.',
                        TEXT_4: 'Se devi modificare alcune caratteristiche del tuo ordine, ti suggeriamo di contattare il nostro servizio clienti per verificare l\'effettivo stato dell\'ordine e valutare la fattibilità della richiesta.',
                        TEXT_5: 'Puoi seguire il tuo ordine dall’area riservata alla sezione "I miei ordini" verificando in ogni momento il suo stato. Inoltre, per ordini spediti, il giorno della spedizione il corriere ti invierà via e-mail il numero di tracking.'
                    },
                    SHIPMENTS: {
                        TITLE_1: 'L\'INDIRIZZO DI SPEDIZIONE PUO\' ESSERE DIVERSO DA QUELLO DI FATTURAZIONE?',
                        TITLE_2: 'POSSO MODIFICARE L\'INDIRIZZO DI SPEDIZIONE DI UN ORDINE GIA\' EFFETTUATO?',
                        TITLE_3: 'POSSO INDICARE PIU\' INDIRIZZI DI SPEDIZIONE SULL\'ORDINE?',
                        TITLE_4: 'EFFETTUATE SPEDIZIONI ALL\'ESTERO?',
                        TEXT_1: 'Sì, sia in fase di registrazione puoi impostare due indirizzi differenti per fatturazione e spedizione, sia in fase di compilazione dell\'ordine puoi modificare l\'indirizzo di spedizione, purché resti all\'interno del paese di fatturazione.',
                        TEXT_2: 'Per ordini non ancora affidati al corriere, possiamo effettuare la modifica; in caso contrario non sempre è possibile ed è necessario inoltrare la richiesta al corriere. Ti suggeriamo di contattare il nostro servizio clienti per verificare l\'effettivo stato dell\'ordine e valutare la fattibilità della richiesta.',
                        TEXT_3: 'Sì. Per ordini con pagamento in carta di credito o bonifico, puoi inserire in fase di ordine più indirizzi di spedizione, riportando per ciascuno le relative quantità da spedire. Il costo della multispedizione è di 12€ + IVA per ciascuna spedizione aggiuntiva. In caso di pagamento in contrassegno, ti suggeriamo di contattare il nostro servizio clienti per valutare la fattibilità della richiesta ed aggiornare l\'ordine.',
                        TEXT_4: 'Sì, in caso di spedizione all\'estero è necessario richiedere un preventivo personalizzato tramite l\'apposito link presente sul sito o inviando una e-mail all\'indirizzo preventivi@pressup.it.',
                    },
                    ADMINISTRATION: {
                        TITLE_1: 'QUALI SONO I TEMPI PER OTTENERE UN RIMBORSO?\n',
                        TITLE_2: 'POTETE EMETTERE FATTURA CON SPLIT PAYMENT?',
                        TITLE_3: 'POTETE EMETTERE FATTURA CON IVA AGEVOLATA AL 4%?',
                        TITLE_4: 'DOVE E QUANDO SONO DISPONIBILI LE FATTURE?',
                        TITLE_5: 'COME POSSO MODIFICARE L\'INTESTAZIONE DELLA FATTURA?',
                        TEXT_1: 'I rimborsi vengono effettuati entro la prima settimana del mese successivo all\'approvazione della richiesta. Il rimborso avviene tramite bonifico bancario sull\'IBAN che ti sarà richiesto dal nostro servizio clienti o dalla nostra amministrazione al momento dell\'apertura della pratica di rimborso. Dove prevista, sarà contestualmente emessa la relativa nota di credito.',
                        TEXT_2: 'Sì, se sei soggetto allo split payment, su specifica richiesta, possiamo emettere fattura con applicazione della scissione dei pagamenti. Ti suggeriamo di inviarci una e-mail all\'indirizzo amministrazione@pressup.it ordini@pressup.it una volta inserito l\'ordine e prima di effettuare il pagamento, così ti forniremo tutte le indicazioni necessarie.',
                        TEXT_3: 'Sì, su specifica richiesta, sia per la stampa di riviste e volumi editoriali sia per la stampa di materiale elettorale.\n' +
                            'Ti suggeriamo di inviarci una e-mail all\'indirizzo ordini@pressup.it una volta inserito l\'ordine e prima di effettuare il pagamento: ti verrà inoltrata una apposita dichiarazione da compilare.\n' +
                            'La nostra policy aziendale riconosce l\'applicazione dell\'iva al 4% su prodotti editoriali qualora vengano riscontrate le seguenti condizioni:\n' +
                            '<ul><li>Qualifica di editore (registrazione presso il Tribunale o esercizio dell\'attività editoriale riscontrabile dal CODICE ATECO indicato in Visura Camerale, o nello Statuto qualora si tratti di associazione)</li>' +
                            '<li>Riviste: testata di copertina regolarmente depositata presso il Tribunale</li>' +
                            '<li>Codice ISBN per la distribuzione del proprio volume editoriale.</li></ul>' +
                            'Effettuate le opportune verifiche, aggiorneremo l\'aliquota iva dell\'ordine.',
                        TEXT_4: 'Tutte le fatture vengono emesse in formato elettronico. La copia di cortesia viene inoltrata via e-mail all\'indirizzo di registrazione e caricata sul portale nella tua area riservata quando l\'ordine risulta spedito.\n' +
                            'Relativamente alle tempistiche di emissione:\n' +
                            '<ul><li>Se il pagamento viene effettuato con bonifico bancario o con carta di credito, le fatture vengono emesse contestualmente alla data di accredito del pagamento<li>' +
                            '<li>Se l\'ordine viene saldato in contrassegno, la fattura viene emessa contestualmente alla spedizione dell\'ordine</li></ul>',
                        TEXT_5: 'La fattura viene emessa secondo i dati inseriti in fase di registrazione. Se si vuole intestare la fattura ad un soggetto diverso dal cliente che ha inserito l\'ordine, è necessario creare un nuovo account e inoltrare la richiesta ad assistenza@pressup.it con il nuovo indirizzo mail e richiedendo l\'associazione tra account e ordine.'
                    }
                },
                PRINTING_TECHNIQUES: {
                    KOMORI_GL840P_H_UV: '- Innovative H-UV rapid drying system\n' +
                        '- 8 color H-UV offset printing\n' +
                        '- Immediate drying of the print\n' +
                        '- Incredible color brilliance\n' +
                        '- High gloss level of the H-UV paint\n' +
                        '- Simultaneous plate change\n' +
                        '- Printing on plastic and synthetic supports\n' +
                        '- Format 72x103 cm\n' +
                        '- 15,000 sheets/h',
                    HEIDELBERG_XL_105: '\n' +
                        '- High flexibility and productivity\n' +
                        '- Exceptional print quality up to 400g\n' +
                        '- Flexo painting unit\n' +
                        '- 6 color offset printing\n' +
                        '- Format 75x105 cm\n' +
                        '- 18,000 sheets/h',
                    HP_INDIGO_12000: '- Eco-sustainability (de-inking and recyclability\n' +
                        '   HP lndigo Electrolnk ink)\n' +
                        '- 7-color digital offset printing (CMYK+3 Pantone/Hexachrome+Pantone)\n' +
                        '- Printed with white ink\n' +
                        '- Inks for special effects (transparent, embossing, relief)\n' +
                        '- Printing of variable data (text and color images)',
                    HP_INDIGO_7900: '- Eco-sustainability (de-inking and recyclability\n' +
                        '   HP lndigo Electrolnk ink)\n' +
                        '- 7-color digital offset printing\n' +
                        '   (CMYK+3 Pantone/Hexachrome+Pantone)\n' +
                        '- Printed with white ink\n' +
                        '- Inks for special effects\n' +
                        '   (transparent, embossing, emboss)\n' +
                        '- Printing of variable data (text and color images)',
                },
                DESIGN_YOUR_IDEA: {
                    TITLE: 'We design your idea',
                    DESIGN_TOGETHER: 'Design together',
                    DESIGN_TOGETHER_SUBTITLE: 'We put our consultants at your disposal to help you turn your ideas into reality',
                    TELL_US_IDEA: 'Tell us your idea',
                    TELL_US_IDEA_SUBTEXT: 'Fill out the form and give your idea a title.\n' +
                        'One of our consultants will contact you to listen to your needs',
                    WE_PRESENT_OUR_PROJECT: 'We present our project to you',
                    WE_PRESENT_OUR_PROJECT_SUBTEXT: 'The consultant shares the project with you\n' +
                        'providing you with methods and times for implementation.',
                    YOUR_IDEA_BECOMES_REALITY: 'Your idea becomes reality',
                    YOUR_IDEA_BECOMES_REALITY_SUBTEXT: 'You approve the project and we make it happen',
                    WHAT_WE_CAN_DO: 'What we can do',
                    WHAT_WE_CAN_DO_SUBTITLE: 'We put our consultants at your disposal to help you turn your ideas into reality',
                    CONSULTANT_DEDICATED: 'A dedicated consultant',
                    DESIGN: 'Design',
                    GRAPHIC_CREATION: 'Graphic creation',
                    PROTOTYPING: 'Prototyping',
                    PRESS: 'press',
                    VISIBILITY: 'Visibility on our channels',
                    SET_UP_VCALL: 'Set up a video call',
                    CONTACT_US: 'Contact us',
                    CONTACT_US_TEXT: 'Pressup: the ideal partner for printing your products. In pressup you can find many supports for the realization of your projects. We offer you a convenient and online printing service. Choose the product, explain your idea to our expert graphic designers, choose the graphic proposal you prefer and print your new personalized promotional items!'
                },
                PRESS_UP_NOW: {
                    TITLE: 'PRESS UP NOW',
                    DESCRIPTION: 'For those of you who clock in at the last minute, PressUP Now has arrived!\n' +
                        'The first same-day printing and delivery service.',
                    CAP_COVERED: 'Check if your zip code is covered by the NOW service',
                    SERVICE_CHECK: 'Check availability of the service',
                    PRODUCT: 'The pressup now service is available for the following products:',
                    BUSINESS_CARD: 'Business cards',
                    FLYERS: 'Flyers',
                    FOLDABLE: 'Foldable',
                    POSTERS: 'Posters',
                    STAPLED_CATALOGUES: 'Stapled catalogues',
                    POSTCARDS: 'Postcards',
                    LETTERHEAD: 'Letterhead',
                    MACHINE_SHEETS: 'Machine sheets'
                },
                GUIDELINES: {
                    TITLE: 'Guidelines pressup',
                    TABS: {
                        GENERAL_INFO: 'Info generali',
                        COLOR_SETTING: 'Impostazioni sui colori',
                        FOLDER_AND_FOLDING_SETTING: 'Impostazioni cartelline & pieghevoli',
                        ACCORDION: {
                            TITLE_1: 'Checklist File',
                            TEXT_1: '<p>Prima di inviarci il file grafico, assicurati di aver spuntato correttamente tutte i punti di questa lista, per avere la sicurezza che il tuo prodotto venga stampato alla massima qualità.</p>' +
                                '<p>Per maggiori informazioni su ognuno degli elementi, leggi le relative sezioni di questa pagina.</p>' +
                                '<p>Checklist:</p>' +
                                '<ul class="p-style ms-3 mb-2"><li>Il formato finale e il numero delle pagine corrispondono al prodotto che hai ordinato sul nostro sito?</li>' +
                                '<li>Il file grafico include 5 mm di margini, e 5 mm di abbondanza?</li>' +
                                '<li>Il file grafico è impostato sul modello quadricromia CMYK?</li>' +
                                '<li>Gli elementi nelle tonalità del grigio sono configurati correttamente come scala di solo Nero (C, M, Y = 0%)?</li>' +
                                '<li>Stai usando il tono composito di nero (K=100%, and C=30%) per grandi aree ed elementi neri?</li>' +
                                '<li>Le linee sottili (meno di 2 punti) e i testi sottili (meno di 8 punti) sono impostati ad un solo colore?</li>' +
                                '<li>I font che non sono convertiti in curve sono inseriti nel file grafico?</li>' +
                                '<li>Il file grafico è salvato come PDF/X-3:2002 con un\'abbondanza di 5 mm?</li></ul>' +
                                '<p>Se hai spuntato tutti gli elementi su questa lista, sei pronto a consegnare il tuo file grafico.</p>' +
                                '<p>Congratulazioni!</p>',
                            TITLE_2: 'Crea un nuovo file',
                            TEXT_2: '<p>Come scegliere correttamente formato, colore e impostazioni di abbondanza fin dall’inizio.</p>' +
                                '<p>Creare un nuovo documento in Adobe Illustrator è molto semplice. Tuttavia, per ottenere risultati di stampa ottimali, ci sono determinati dettagli che dovresti tenere a mente. Li illustreremo nel seguente tutorial.</p>' +
                                '<p>Per iniziare, apri Adobe Illustrator e seleziona <b>File → Nuovo...</b></p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_1.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p>Si aprirà una nuova finestra, permettendoti di selezionare le impostazioni per il tuo nuovo documento. Prima di tutto, definisci il titolo del tuo documento nel campo Nome, e seleziona <b>Stampa</b> nel campo <b>Profilo</b>.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_2.jpg" alt="img" class="w-75-res mb-2">' +
                                '<p>Poi, devi definire le dimensioni del documento.</p>' +
                                '<p>Come esempio, useremo biglietti da visita con un formato di 85 mm x 55 mm.</p>' +
                                '<p>Dal menu a tendina <b>Unità</b>, puoi scegliere l’unità di misura che vorresti usare. Normalmente, si usano mm o cm. Nei campi <b>Larghezza</b> e <b>Altezza</b> imposta larghezza e altezza con le quali vorresti stampare il documento.</p>' +
                                '<p>Devi anche impostare l’abbondanza di 5 mm. Questo garantisce che ci sia un margine di sicurezza durante il processo di taglio.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_3.jpg" alt="img" class="w-75-res mb-2">' +
                                '<p>Infine, controlla in <b>Opzioni Avanzate</b> che il metodo colore sia impostato a CMYK, e che gli effetti di rasterizzazione siano impostati come <b>Alta (300 ppi)</b>. Queste impostazioni aiutano a massimizzare la qualità del prodotto di stampa.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_4.jpg" alt="img" class="w-75-res mb-2">' +
                                '<p>Seleziona il profilo <b>Colore CMYK</b></p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_5.jpg" alt="img" class="w-75-res mb-2">' +
                                '<p>Il tuo documento è adesso impostato correttamente, e puoi iniziare a lavorare!</p>',
                            TITLE_3: 'Usa immagini ad alta risoluzione',
                            TEXT_3: '<p>Quando usi immagini nella tua tavola di disegno, dovresti farlo molto attentamente. Solo perché le immagini appaiono bene sul monitor, questo non significa necessariamente che appariranno bene sul prodotto finale stampato.</p>' +
                                '<p><b>Importante: tutte le immagini devono essere in metodo colore CMYK e non in RGB.</b></p>' +
                                '<p>Le immagini possono essere di due tipi: immagini bitmap e immagini vettoriali.</p>' +
                                '<p>Le immagini bitmap sono costituite da una serie di piccoli punti chiamati pixel. Questi pixel sono organizzati insieme in un pattern che forma un’immagine. Quando zoommi sull’immagine ad un certo punto inizierai a vedere i singoli pixel che contribuiscono a formare l’immagine. A confronto, le immagini vettoriali non sono basate su pixel, ma su formule matematiche che creano determinate linee e curve. Quando fai lo zoom su un’immagine vettoriale, questa è altrettanto precisa come nella sua dimensione originale.</p>' +
                                '<p>Per assicurarti della nitidezza delle immagini bitmap dovresti controllare la risoluzione (DPI) delle immagini nel quadro di disegno. L’unità di misura DPI (dots per inch) misura la quantità di pixel per pollice.</p>' +
                                '<p>Un DPI basso porterà ad un’immagine di bassa qualità e sfocata.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_20.jpg" alt="img" class="w-75-res mb-2">' +
                                '<p>Qui sopra a destra puoi vedere un’immagine Bitmap con una risoluzione di 72 pixel per pollice, mentre sulla sinistra vediamo un’altra immagine con 300 pixel per pollice. Puoi vedere chiaramente che la qualità della foto a destra risente della bassa risoluzione di 72 dpi.</p>' +
                                '<p>Per la stampa, assicurati che le tue immagini bitmap abbiano una risoluzione di almeno 300 DPI per garantire risultati di stampa ottimali.</p>' +
                                '<p>Puoi verificare la risoluzione delle immagini bitmap nel tuo quadro di lavoro in questo modo: Apri il quadro immagine in Adobe Illustrator e vai a <b>Finestra → Informazioni</b> sul documento.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_21.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p>Per verificare la risoluzione delle immagini, vai a <b>Informazioni Documento</b>.</p>' +
                                '<p>Così si apre una finestra grazie alla quale puoi controllare le informazioni del documento nel dettaglio. Clicca sul bottone <b>Più opzioni</b> in alto a destra della finestra, e seleziona l’opzione <b>Immagini Incorporate</b>.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_22.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p>Poi, seleziona l’immagine che vorresti controllare. La finestra delle informazioni sul documento mostrerà quindi la risoluzione dell’immagine sotto Risoluzione.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_23.jpg" alt="img" class="w-75-res mb-2">' +
                                '<p>Selezione l’immagine che vorresti controllare, e controlla se la risoluzione è a 300 o di più. Assicurati che la risoluzione sia almeno 300x300 pixels/inch. Se la risoluzione è minore, l’immagine potrebbe apparire pixellata sul prodotto di stampa.</p>' +
                                '<p>Nota bene: non c’è modo di trasformare un’immagine in bassa risoluzione in un’immagine in alta risoluzione. E’ necessario acquisire l’immagine bitmap in una risoluzione maggiore e sostituirla nel quadro di lavoro.</p>',
                            TITLE_4: 'Converti i fonts in tracciati',
                            TEXT_4: '<p><b>Come usare i font</b></p>' +
                                '<p>I font sono set di caratteri di scrittura che puoi installare sul tuo computer. Quando vengono installati, puoi usarli in qualsiasi file o tipo di lavoro che crei con il tuo device.</p>' +
                                '<p>Nonostante ciò, aprire quei file su un altro computer potrebbe presentare problemi qualche volta. I problemi che si potrebbero presentare riguardano l’aspetto del testo se il font non è stato ancora installato sul computer in questione. In questo modo, il computer non ha le informazioni richieste per mostrare e modificare quei testi correttamente.</p>' +
                                '<p>Per evitare questo tipo di problemi, è importante che tu converta quei font nel tuo quadro di lavoro in curve. In questo tutorial ti mostreremo come fare.</p>' +
                                '<p><span class="highlighted-yellow">Salvare file di produzione grafica come PDF/X3:2002 elimina la necessità di conversione manuale in curve.</span></p>' +
                                '<p><b>Come convertire font in curve</b></p>' +
                                '<p><span class="highlighted-yellow"><b>IMPORTANTE</b> prima di continuare: assicurati di salvare il documento originale con un nome diverso, perché una volta che questo processo è completato non sarai più in grado di modificare il testo.</span></p>' +
                                '<p>Per prima cosa, devi identificare i font che vuoi convertire in curve, e trovare la loro posizione nel quadro di lavoro. Lo strumento "Trova Font" ti permetterà di fare esattamente questo.</p>' +
                                '<p>Nel Menu in alto, clicca su <b>Testo → Trova Font</b>. La finestra che si apre elencherà tutti i diversi font nel tuo documento.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_26.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p>Usa lo strumento per trovare tutti i font nel tuo documento Per rintracciare i font nel tuo quadro di lavoro, seleziona gli elementi della lista uno per uno e clicca <b>Trova</b> per ognuno per evidenziarli nel quadro di lavoro. Trova ognuno di loro cliccando su Trova.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_27.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p>Una volta che tu sai dove sono i font, selezionali singolarmente. Per convertire i font selezionati in curve, vai al Menu in alto e clicca su <b>Carattere → Crea contorno</b>.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_28.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p>Seleziona ogni font singolarmente e clicca su Crea contorno</p>' +
                                '<p>Questo processo converte i tuoi font in curve evitando modifiche nell’aspetto del file quando si cambia computer. Con un paio di clic, puoi convertire tutti i font del tuo documento per una compatibilità perfetta.</p>' +
                                '<p>Ricorda che convertire i font in curve ti impedirà di modificare ulteriormente il testo.</p>',
                            TITLE_5: 'Salva un PDF in formato PDF-X3:2002',
                            TEXT_5: '<p>Prima di salvare il tuo file grafico come PDF come descritto in questo tutorial, assicurati che i margini di sicurezza e l’abbondanza siano compatibili con i nostri requisiti.</p>' +
                                '<p>Dopo esserti assicurato che il tuo file sia pronto per la stampa, clicca su <b>File → Salva</b> come dal Menu in alto.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_29.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p>Sotto Formato, scegli Adobe PDF (pdf), e poi clicca su <b>Salva</b>. Si aprirà una nuova finestra per le specifiche del PDF.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_30.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p>In questa finestra, imposta il campo Adobe PDF Preset con l’opzione <b>Stampa in alta qualità</b>. In alcune versioni di Adobe Illustrator, questo menu potrebbe mostrare soltanto un’opzione <b>Modificato</b>. In ogni caso, imposta il modello standard a PDF/X-3:2002, e spunta la voce <b>Ottimizza per Visualizzazione web veloce</b>, e <b>Visualizza PDF</b> dopo aver salvato le opzioni nel pannello delle opzioni.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_31.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p><i>Scegli lo standard PDF/X-3:2002 e imposta Stampa in alta qualità.</i></p>' +
                                '<p>A quel punto, seleziona <b>Margini e Abbondanze</b> sulla parte sinistra, e spunta le caselle delle voci <b>Segni di taglio</b> e <b>Margini di registrazione</b> nel <b>pannello Margini</b>, per assicurarti che il prodotto di stampa venga tagliato correttamente. Nel <b>pannello Abbondanze</b>, spunta l’opzione <b>Usa impostazioni di abbondanza</b>.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_32.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p>Dopo esserti assicurato ancora una volta che tutte le impostazioni siano state selezionate come descritto qui sopra, clicca su Salva PDF.</p>',
                            TITLE_6: 'Verifica linee sottili e fonts',
                            TEXT_6: '<p>Con la stampa offset, ci sono inevitabilmente variazioni di 2 o 3 decimi di millimetri per ogni elemento che va in stampa. Per elementi grandi e medi, questo cambiamento non verranno notati. Ma per oggetti più piccoli e per linee e testi sottili, di dimensioni minori di 8 punti, la differenza potrebbe diventare più visibile e potrebbe portare a una perdita di leggibilità e a sfocatura.</p>' +
                                '<p>Per evitare complicazioni, suggeriamo di evitare di usare più di 2 colori per una composizione di elementi piccoli.</p>' +
                                '<p>Per esempio, guarda alla lettera seguente (zoommata):</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_36.jpg" alt="img" class="w-25-res mb-2">' +
                                '<p><i>Differenza con font zoommato con uno e 4 colori</i></p>' +
                                '<p>Comporre usando 4 colori può seriamente minare la leggibilità del testo, mentre solo un colore non influenza la qualità della lettura.</p>' +
                                '<p>Per linee sottili (con uno spessore inferiore ai 2 punti), si applica la stessa logica. Per esempio, vuoi stampare una linea marrone. Tre colori sono necessari per creare il marrone: ciano, magenta e giallo. Di conseguenza, potrebbe esserci un errore con la stampa se la linea è sottile: se la linea è più sottile di 2 punti, la tua linea potrebbe finire con cinque diversi colori invece di una sola (come desiderato).</p>' +
                                '<p>Nel nostro esempio, la linea dovrebbe essere o in un solo colore (esempio: nero) se sottile, o spessa almeno di 3-4 punti se composta da più di 2 colori.</p>' +
                                '<p><span class="highlighted-yellow">Possiamo invece stampare linee "K=100" o grayscale di K, fino a un minimo di 0.5 punti equivalenti a 0.175mm</span></p>' +
                                '<p><span class="highlighted-yellow">Riguardo i font, anche un corpo 6 è stampabile molto bene, con l\'accortezza che i testi neri siano K=100 o in un grayscale di solo K</span></p>',
                            TITLE_7: 'Imposta il documento in quadricromia',
                            TEXT_7: '<p>Idealmente, dovresti impostare il tuo documento in CMYK quando hai iniziato a lavorare sul tuo file. In caso non lo avessi ancora fatto, segui questo tutorial per cambiare il tuo file in metodo colore CMYK.</p>' +
                                '<p>Durante il processo di stampa offset, usiamo CMYK, che è un modello cromatico in cui tutti i colori sono descritti come un mix dei 4 colori di processo (quadricromia): ciano, magenta, giallo e nero.</p>' +
                                '<p>Per assicurarti che il tuo documento sia in metodo colore CMYK, puoi aprire <b>Finestra → Informazioni Documento</b> e verificare che il file sia in metodo colore CMYK.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_24.jpg" alt="img" class="w-25-res mb-2">' +
                                '<p>Apri <b>Informazioni Documento</b> per verificare che il tuo lavoro sia impostato in CMYK.</p>' +
                                '<p>Se il documento non è in CMYK, vai alla voce <b>File → Metodo colore documento → CMYK</b> in modo da cambiare il metodo colore.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_25.jpg" alt="img" class="w-50-res mb-2">',
                            TITLE_8: 'Imposta correttamente i grigi',
                            TEXT_8: '<p>Grigio è un colore intermedio tra nero e bianco, e deve essere trattato molto attentamente. Nel metodo CMYK, il grigio viene ottenuto aggiungendo nero (K). Per comporre un tono di di grigio, assicurati di impostare il tuo colore nero (K) a una certa percentuale tra 5% e 100%. Evitare di usare gli altri tre colori (impostando ciano, magenta e giallo a 0%). Usare ciano, magenta e giallo per comporre toni di grigio causerebbe risultati di stampa non ottimali.</p>' +
                                '<p>Gli esempi qui sotto mostrano la modalità corretta e scorretta di impostare i colori grigi.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_33.jpg" alt="img" class="w-75-res mb-2">' +
                                '<p>I colori grigi K possono variare tra 5% e 100%, ma C, M e Y devono essere impostati a 0.</p>' +
                                '<p><span class="highlighted-yellow">È sconsigliato scendere al disotto del 5% di nero (K).</span></p>' +
                                '<p>La parte sinistra mostra come impostare correttamente colori grigi. Usando solo la scala di nero assicura che la aree e gli elementi grigi nel quadro di lavoro vengano settati correttamente per la stampa.</p>' +
                                '<p>La parte destra illustra la modalità sbagliata. Usare colori (CMY) e nero (K) sovraccaricherà il grigio. Inoltre, questo aggiunge complessità dato che il grigio non sarà più composto da un colore, ma da colori multipli.</p>' +
                                '<p>Per impostare aree nere e elementi di stampa, consulta la sezione "Imposta correttamente il nero"</p>',
                            TITLE_9: 'Imposta correttamente il nero',
                            TEXT_9: '<p>Il nero è un colore largamente usato nel campo del graphic design, perché le sue caratteristiche possono essere molto utili in termini di contrasto, peso e forza. Tuttavia, il nero può anche causare problemi durante la stampa, dovuti principalmente a errori nel settaggio dei programmi di editing.</p>' +
                                '<p>Per testi, linee sottili ed elementi piccoli, assicurati sempre che usi nero puro, ossia Nero (K) impostato al 100%, mentre gli altri tre colori (C, M e Y) sono impostati a 0%.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_34.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p>Per aree ed elementi più grandi, usa una forma composita di nero, ossia Ciano (C) = 30%, Nero (K) = 100%, Magenta (M) = 30% e Giallo (Y) = 0%.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_35.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p>Queste istruzioni devono essere applicate in qualsiasi programma di editing (Adobe Photoshop, Adobe Indesign, ecc.), anche se sono state originariamente impostate in Adobe Illustrator. Questo perché le palette cromatiche funzionano nello stesso modo in qualsiasi programma.</p>',
                            TITLE_10: 'Impostare i file per i pieghevoli',
                            TEXT_10: '<p>Nell’impostare un file grafico che contiene piegature, è importante essere coscienti della necessità dei margini di sicurezza su ogni lato delle linee di piega.</p>' +
                                '<p>Data la natura meccanica di una piegatrice, la reale linea di piega sui tuoi prodotti finiti potrebbe variare da 2 a 3 mm da ogni lato della linea. Conseguentemente, se non includi un margine di sicurezza da ciascuno dei lati della linea di piega, la piega potrebbe finire sul testo, sulle immagini o su altri contenuti.</p>' +
                                '<p>Suggeriamo sempre di lasciare un margine di 5 mm su ogni lato della linea di piega per assicurarti che il tuo contenuto sia 100% al sicuro.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_37.jpg" alt="img" class="w-75-res mb-2">' +
                                '<p>Lascia un margine di sicurezza di 5 mm su ogni lato della linea di piega.</p>' +
                                '<p>Per riassumere, se vuoi ricevere il tuo prodotto stampato nella sua forma completa, senza errori o deviazioni dal design originale, lasciare un margine di sicurezza da ogni lato della linea di piega è fondamentale!</p>' +
                                '<p>Se hai domande dopo aver letto la nostra sezione di assistenza, o vorresti la nostra assistenza nel lasciare un margine di sicurezza appropriato, sentiti libero di contattarci in qualsiasi momento.</p>',
                            TITLE_11: 'Impostare i file per le cartelline',
                            TEXT_11: '<p>Per impostare correttamente il file grafico per una cartellina è necessario scaricare il relativo template e aprirlo con Adobe Illustrator.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_38.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p>È fondamentale mantere inalterato il livello fustella, pertanto per comodità consigliamo di bloccarlo e di creare un nuovo livello nominandolo "Grafica".</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_39.jpg" alt="img" class="w-75-res mb-2">' +
                                '<p>Su questo nuovo livello andranno posizionati gli elementi grafici sempre ad una distanza di sicurezza di almeno 5 mm dalle linee di taglio.</p>' +
                                '<p>Terminata l\'elaborazione grafica salvare il correttamente il PDF in X-3:2002.</p>',
                            TITLE_12: 'Imposta il formato',
                            TEXT_12: '<p>Prima di caricare il tuo file, dovresti assicurarti che il formato del file e il numero delle pagine corrispondano al prodotto che hai ordinato sul sito:</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_6.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p>In questo tutorial, ti mostreremo come controllare o cambiare le dimensioni del tuo file e il numero delle pagine.</p>' +
                                '<p>Prima, selezione lo strumento <b>Tavola Disegno</b> nella barra del menu:</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_7.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p>Dopo aver cliccato sul pulsante dello strumento Tavola Disegno, il tuo schermo cambierà nella visualizzazione Tavola Disegno. Questa visualizzazione ti permette di controllare e, se necessario, modificare le dimensioni della tua tavola da disegno.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_8.jpg" alt="img" class="w-75-res mb-2">' +
                                '<p>Dopo esserti assicurato che il formato sia corretto, esci dallo strumento Tavola da disegno.</p>',
                            TITLE_13: 'Imposta margini e abbondanze',
                            TEXT_13: '<p>Per ottenere risultati di stampa ottimali, assicurati che i tuoi file abbiano 5 mm di abbondanza e 5 mm di margine di sicurezza. Questo passaggio è molto importante, perché durante il processo di taglio, potrebbe accadere che il taglio sia eccessivo all’interno (per questo motivo è consigliato un margine di sicurezza) o all’esterno (per questo motivo serve l’abbondanza).</p>' +
                                '<p>Il margine di sicurezza garantisce che nessuno contenuto venga tagliato fuori dal file grafico. L’abbondanza garantisce che non ci sarà nessun bordo bianco ai marigni del prodotto.</p>' +
                                '<p><b>Impostare l\'abbondanza</b></p>' +
                                '<p>Dopo aver aperto il tuo file, clicca sulla voce <b>Imposta documento</b>.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_9.jpg" alt="img" class="w-75-res mb-2">' +
                                '<p>Si aprirà una nuova finestra, attraverso la quale puoi impostare l’abbondanza a 5 mm su tutti i lati del file.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_10.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p>Dopo aver cliccato su OK, un contorno rosso intorno al file grafico indicherà fino a che punto gli elementi grafici devono abbondare, per raggiungere un’abbondanza sufficiente di 5 mm.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_11.jpg" alt="img" class="w-75-res mb-2">' +
                                '<img src="../assets/images/press-up/linee-guida/guida_12.jpg" alt="img" class="w-75-res mb-2">' +
                                '<p>Senza abbondanza, informazioni importanti potrebbero essere esclusi. Con l’abbondanza, qualsiasi informazione sul tuo file è al sicuro.</p>' +
                                '<p><b>Impostare i margini di sicurezza</b></p>' +
                                '<p>Per mostrare margini di sicurezza in Adobe Illustrator, devi usare il seguente metodo, perché margini di sicurezza non sono supportati automaticamente in Illustrator. Prima di tutto, seleziona lo <b>Strumento Rettangolo</b> e clicca in un punto qualsiasi dello schermo.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_13.jpg" alt="img" class="w-25-res mb-2">' +
                                '<p>Si aprirà una nuova finestra, dove puoi inserire le dimensioni del rettangolo. Imposta le dimensioni del rettangolo a 10mm meno larghe e 10mm meno alti del file grafico, per assicurare un margine di sicurezza di 5 mm su tutte le pagine.</p>' +
                                '<p>Per esempio, se i tuoi biglietti da visita hanno un formato di 85mm x 55mm, imposta le dimensioni del rettangolo a 75mm x 45mm.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_14.jpg" alt="img" class="w-25-res mb-2">' +
                                '<p>Imposta le dimensioni del rettangolo a 5 mm in meno rispetto alla larghezza e 5 mm in meno rispetto all’altezza del tuo file grafico.</p>' +
                                '<p>Clicca OK per creare il rettangolo. Poi, centralo sulla tavola di disegno (<b>Finestra → Allinea</b>).</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_15.jpg" alt="img" class="w-75-res mb-2">' +
                                '<p>Nell’angolo superiore destro di questa finestra, clicca su <b>Più opzioni</b> e poi seleziona <b>Mostra opzioni</b>.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_16.jpg" alt="img" class="w-25-res mb-2">' +
                                '<p>Allinea il rettangolo alla tua tavola di disegno cliccando su <b>Allinea e centra orizzontalmente e verticalmente</b>.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_17.jpg" alt="img" class="w-25-res mb-2">' +
                                '<p>Mentre selezioni il rettangolo, clicca con il tasto destro in un punto qualsiasi dello schermo e seleziona <b>Crea Guide</b>.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_18.jpg" alt="img" class="w-75-res mb-2">' +
                                '<p>A questo punto, abbondanza e margini di sicurezza sono impostati correttamente. Assicurati che tutti gli elementi grafici si estendano fino al bordo rosso, e che nessuna informazione si trovi tra il bordo rosso e il bordo azzurro, perché quell’informazione potrebbe essere esclusa durante il processo di taglio.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_19.jpg" alt="img" class="w-50-res mb-2">'
                        }
                    }
                },
                FOR_A_EURO: {
                    TITLE: 'Provaci a 1 €',
                    TEXT: 'Mettiamo a tua disposizione i nostri consulenti per aiutarti a trasformare le tue idee in realtà',
                    ICON: {
                        ICON_1: 'Scegli il prodotto',
                        ICON_2: 'Registrati',
                        ICON_3: 'Carica il tuo file',
                        ICON_4: 'Ricevi il prodotto',
                    }
                }
            },
            STEPPER: {
                NAMES: {
                    FIRST_STEP: 'Personal Data',
                    SECOND_STEP: 'Documents',
                    THIRD_STEP: 'Beneficial Owner',
                    FOURTH_STEP: 'Accounting Data',
                    FIFTH_STEP: 'Signatory Data'
                },
                PERSONAL_DATA_FIELDS: {
                    COMPANY_NAME: 'Company name',
                    EMAIL: 'E-mail',
                    VAT: 'VAT',
                    CAP: 'CAP',
                    PEC: 'Email PEC',
                    COUNTRY: 'Country',
                    CFIS: 'CFIS',
                    LEGAL_FORM: 'Leagal Form',
                    PROVINCE_REGION_BUSINESS: 'Province reg. business',
                    REA: 'REA',
                    DATE_INCORPORATION: 'Date of incorporation',
                    ATECO_NACE: 'Ateco/nace',
                    ATECO_DESCRIPTION: 'Ateco Description',
                    ACTIVITY_DESCRIPTION: 'Activity description',
                    NAME: 'Name',
                    SURNAME: 'Surname',
                    ADDRESS: 'Address',
                    HOUSE_NUMBER: 'House number',
                    CITY: 'City',
                    PROVINCE: 'Province',
                    DATE_OF_BIRTH: 'Date of birth',
                    NATIONALITY: 'Nationality',
                    COUNTRY_OF_RESIDENCE: 'Country of residence',
                    COUNTRY_CODE: 'Country code',
                    STATE_CODE: 'State code',
                    PHONE: 'Phone',
                    CONTACTS: 'Contacts',
                    RECIPIENT: 'Recipient',
                    LOCATION: 'Location',
                    TAX_ID_CODE: 'Tax id code',
                    DESCRIPTION: 'Address description',
                    DIFFERENT_ADDRESS: 'Shipping address different from billing address',
                },
                CHECKBOX: {
                    SHIPPING_BILLING_MATCH: 'The shipping and billing addresses are the same',
                    MAIN_ADDRESS: 'Main shipping address'
                },
                SIGNATORY: {
                    SIGNATORY_DATA: 'Signatory data',
                    NAME: 'Name',
                    SURNAME: 'Surname',
                    EMAIL: 'Email',
                    PHONE_NUMBER_NATIONALITY: 'Phone number nationality',
                    PHONE: 'Phone',
                    CLICK_ON_ADD: 'Click on "Add" for add new signatory',
                },
                NO_ELEMENTS_TO_SHOW: {
                    CLICK_TO_INSERT: 'Click the "add address" button to get started',
                    NO_ACCOUNT_DATA: 'Complete your account details',
                    CLICK_TO_INSERT_REPORTING: 'Click the "new reporting" button to get started',
                }
            },
            OFFERS: {
                OFFERS: 'Offers',
                PRESSUP_OFFERS: 'Pressup offers'
            },
            SEARCH: {
                RESULT: 'Here are the results of your search for',
                NO_RESULT: 'No result founded',
                SEARCH: 'Search',
                RESULT_SHOWED: 'Result showed {{from}} - {{to}} di {{total}} results',
            },
            TRUSTPILOT: {
                SHOW_MORE_REVIEWS: 'Show more reviews',
                REVIEWS_TITLE: 'Online Digital Printing: customization and fast delivery - PressUP',
            }
        },
        COMMUNICATION: {
            LOG_IN_EMAIL: 'Log in to your email',
            EMAIL_SENT: 'To complete the registration log in to your email and click on the link we sent you.',
            WE_ARE_ALMOST_THERE: 'We are almost there...',
            VERIFYING_INFO: 'We are verifying your information...',
            DID_NOT_RECEIVE_EMAIL: 'Didn\'t receive the email?'
        },
        MODAL: {
            FIELD_UPDATED_CORRECTLY: 'Fields updated successfully',
            SOMETHING_WENT_WRONG: 'Something went wrong',
            WRONG_CREDENTIAL: 'Incorrect credentials',
            INCORRECT_CREDENTIALS_TEXT: 'We recommend that you check and reinsert them correctly.',
            COMMUNICATION_ERROR: 'Error communicating with the server',
            CREDENTIALS_ALREADY_USED: 'Credentials already in use',
            VALIDATION_SUCCESS: 'Validation carried out successfully',
            GO_TO_LOGIN_FROM_VALIDATION: 'Take me to login',
            PASSWORD_UPDATED: 'Password updated successfully',
            TOKEN_EXPIRED: 'Token expired, try again',
            ADD_NOTIFICATION_SUCCESS: 'Notification added successfully',
            ERROR_DELETING_ITEM: 'Error deleting item',
            ADDRESS_SAVED: 'Address saved successfully',
            PROBLEM_ADDRESS_SAVED: 'Error saving address. Recheck the fields.',
            PROBLEM_ADDRESS_MODIFIED: 'Error changing address. Check the fields.',
            ADDRESS_MODIFIED: 'Address changed successfully',
            DELETE_ADDRESS: 'Delete the selected item?',
            COMPLETE_ACCOUNT: 'Complete the registration by entering the requested data in account data',
            MEX_SEND_CURR: 'Messages sent successfully',
            TOTAL_AMOUNT_MULTI_SUBJECT: 'The additional price is ',
            UPLOAD_FILE_SUCCESS: 'The upload was successful',
            UPLOAD_FILE_ERROR: 'The upload service did not work correctly, please try again later',
            ORDER_ITEM_ID_ERROR: 'It is not possible to request assistance for this process',
            ARE_SURE_DELETING_PRODUCT: 'Are you sure you want to delete the selected product?',
            DELETE_ALL_CART: 'Are you sure you want to delete the cart?',
            CAPTCHA_TITLE: 'Safety check',
            CAPTCHA_SUBTITLE: 'To continue enter the text you see in the image.',
            SEND_MAIL_ERROR: 'E-mail not sent correctly.',
            NUMBER_ERROR: 'The number of copies to be sent is different from the number of copies to be printed',
            QUOTE: 'Your request has been sent, we will reply as soon as possible.',
            PAYMENT_DONE: 'Payment made successfully',
            PAYMENT_ERROR: 'An error occurred while processing your payment.',
            RETRY: 'Please try again or contact support.'
        },
        TOAST: {
            SERVER_ERROR: 'There was a problem recovering the data. Please try again later.',
            GENERAL_CALL_ERROR: 'There was a problem retrieving {{call}} data',
            GENERAL_CALL_ERROR_SIMPLE: 'Si è verificato un problema nel recupere i dati',
            SUCCESS_DELETING: '{{item}} deleted successfully',
            ERROR_DELETING: 'There was a problem deleting {{item}}',
            ERROR_ON_OPERATION: 'An error occurred in the operation',
            OPERATION_SUCCESS: 'Operation carried out successfully'
        },
        PREVENTIVATOR: {
            ADD_CART: 'Add cart',
            DOWNLOAD_TEMPLATE: 'Download template',
            SUMMARY_PROCESSING: 'Summary processing',
            QUOTATION_AND_DELIVERY: 'Quotation and delivery',
            DELIVERY: 'Delivery',
            NET_PROCESSING: 'Net proces.',
            DISCOUNTED_PRICE: 'Discounted price',
            VAT_22: 'VAT',
            TOTAL_ESTIMATE: 'Tot. estimate',
            NAME: 'Name',
            NAME_PROCESSING: 'Name processing'
        }
    }
};
