import {BrowserModule, provideClientHydration} from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
    HTTP_INTERCEPTORS,
    withFetch,
    provideHttpClient,
    withInterceptorsFromDi,
    HttpClient
} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {ToastrModule} from 'ngx-toastr';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {SharedModule} from './shared/shared.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ShopComponent} from './shop/shop.component';
import {PagesComponent} from './pages/pages.component';
import {ElementsComponent} from './elements/elements.component';
import {CommonModule, IMAGE_CONFIG, IMAGE_LOADER, ImageLoaderConfig} from '@angular/common';
import {InterceptService} from './core';
import {AuthService} from './modules/auth/services/auth.service';
import {environment} from '../environments/environment';
import {PopupCookiesComponent} from 'ngx-cookies-preference';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {clearStateMetaReducer} from './core/ngrx/stores/reducers/clear-state-meta-reducer';
import {accountDataFeature} from './core/ngrx/stores/features/account-data-features';
import {navigationFeatures} from './core/ngrx/stores/features/navigation-features';
import {userInitializedFeatures} from './core/ngrx/stores/features/user-initializedFeatures';
import {uploadingFileOrderFeatures} from './core/ngrx/stores/features/uploading-file-order-features';
import {assistanceFeatures} from './core/ngrx/stores/features/assistance-features';
import {Observable, of, switchMap, tap} from 'rxjs';
import {cartFeatures} from './core/ngrx/stores/features/cart-features';
import {catchError} from 'rxjs/operators';
import {CartItem, CartItemsModel} from './shop/cart/models/cart-items.model';
import {NgxPayPalModule} from 'ngx-paypal';
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {FacebookLoginProvider, GoogleLoginProvider} from '@abacritt/angularx-social-login';

export function HttpLoaderFactory(http: HttpClient): any {
    return new TranslateHttpLoader(http);
}

export function appInitializer(authService: AuthService): () => Observable<void | CartItem | CartItemsModel> {
    return () => authService.getUserByToken().pipe(
        switchMap(user => user ? authService.getCartStatus(user.username) : of(null)),
        tap(cartStatus => {
            if (cartStatus) {
                authService.setCartStore(cartStatus);
            }
        }),
        catchError(err => {
            console.error('Error during initialization:', err);
            return of(null);
        })
    );
}

@NgModule({
    declarations: [
        AppComponent,
        ShopComponent,
        PagesComponent,
        ElementsComponent
    ],
    bootstrap: [AppComponent],
    imports: [CommonModule,
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        BrowserAnimationsModule,
        NgbModule,
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            progressBar: false,
            enableHtml: true,
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SharedModule,
        AppRoutingModule,
        NgxPayPalModule,
        PopupCookiesComponent,
        StoreModule.forRoot({}, {metaReducers: [clearStateMetaReducer]}),
        StoreModule.forFeature(accountDataFeature),
        StoreModule.forFeature(navigationFeatures),
        StoreModule.forFeature(userInitializedFeatures),
        StoreModule.forFeature(uploadingFileOrderFeatures),
        StoreModule.forFeature(assistanceFeatures),
        StoreModule.forFeature(cartFeatures),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        })
    ],
    providers: [
        provideHttpClient(withFetch(), withInterceptorsFromDi()),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptService,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializer,
            multi: true,
            deps: [AuthService],
        },
        {
            provide: IMAGE_CONFIG,
            useValue: {
                placeholderResolution: 40,
                disableImageSizeWarning: true
            }
        },
        {
            provide: IMAGE_LOADER,
            useValue: (config: ImageLoaderConfig) => {
                return `${environment.addressPressupBackoffice}${config.src}`;
            }
        },
        {provide: LOCALE_ID, useValue: 'it-IT'},
        provideClientHydration(),
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(
                            '33743734306-m51otq7sb833fmp6mo281q8ant5lf5ls.apps.googleusercontent.com'
                        )
                    },
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider('623766555654084') // ID FOR DEVELOPER
                    }
                ],
                onError: (error) => {
                    console.error(error);
                }
            }
        },
    ]
})
export class AppModule {
}
